// Occupation Access Rights
import React from 'react';
import {Provider} from 'react-redux';
import ReactOnRails from 'react-on-rails';
//import { AppContainer } from 'react-hot-loader';
import ReactDOM from 'react-dom';

import MainContainer from '../containers/MainContainer.jsx';

export default (props, _railsContext, domNodeId) => {
  const render = props.prerender ? ReactDOM.hydrate : ReactDOM.render;
  delete props.prerender;

  const store = ReactOnRails.getStore('SharedStore');

  const element = (
      <Provider store={store}>
        <MainContainer />
      </Provider>
  );
  render(element, document.getElementById(domNodeId));
};
