import React, { Component } from 'react';

import { postChanges } from '../utils';
import { t } from '../../Core/locale/locale.jsx'
import Icons from '../../Core/assets/icons/fa-custom.jsx';
import '../../Core/assets/styles/fa-custom.scss';
import '../assets/styles/styles.scss';

export default class RowMenuRenderer extends Component {
  constructor(props) {
    super(props);
    this.parentProps = this.props.context.thisComponent.props
    this.state = {
      level: props.node.level,
      hasChildren: (props.data.child_r || props.data.child_u || props.data.child_c || props.data.child_d),
      showAddCreateToReadMenu: this.hasReadWithoutCreateChildren(),
      showReadOnlyMenu: (props.data.child_u || props.data.child_c || props.data.child_d),
      menuOpen: false,
      menuDescriptionOpen: false,
      isPosting: this.parentProps.oRights.isPosting,
      canManage: this.parentProps.railsContext.currentUser.currentOccupation.personnel_manage || this.parentProps.railsContext.currentUser.is_admin
    };
  }
  
  hasReadWithoutCreateChildren() {
    if (this.props.node.level!==2 || !this.props.data.child_r)
      return false
    return !!this.parentProps.oRights.rowData.find( row => row.budid===this.props.data.budid && row.sbudid && row.r && !row.c )
  }

  closeMenu() {
    if (this.state.menuOpen) {
      this.setState({menuOpen: false})
      this.props.api.deselectAll()
    }
  }
  
  toggleMenu() {
    if (this.state.menuOpen) {
      this.closeMenu()
    } else {
      this.setState({menuOpen: true, isPosting: false})
      this.props.node.setSelected(true)
    }
  }

  toggleMenuDescription = () => {
    this.setState( prevState => ({menuDescriptionOpen: !prevState.menuDescriptionOpen}))
  }

  async menuClicked(choice) {
    var roles = [], rolesToChange
    switch (choice) {
      case 'addCreateToRead':
        rolesToChange = this.parentProps.oRights.rowData.filter( row => row.r && !row.c && row.budid===this.props.data.budid && !!row.sbudid )
        roles = rolesToChange.map( row => {
          return {
            target_id: row.sbudid,
            target_type: 'SubBudget',
            r: 1,
            u: (row.u || false) * 1,
            c: 1,
            d: (row.d || false) * 1,
          }
        })
        break
      case 'readOnly':
        rolesToChange = this.parentProps.oRights.rowData.filter( row => (row.c || row.u || row.d) && row.budid===this.props.data.budid && !!row.sbudid )
        roles = rolesToChange.map( row => {
          return {
            target_id: row.sbudid,
            target_type: 'SubBudget',
            r: (row.r || false) * 1,
            u: 0,
            c: 0,
            d: 0,
          }
        })
    }
    if (roles.length>0) {
      this.parentProps.actions.postRequest()
      this.setState({ isPosting: true })
      await postChanges( roles, this.parentProps.oRights.occupationId, this.parentProps.actions )
    }
    // Edge and Firefox do not receive Blur event properly, so doing manual cleanup after posting
//    console.log("Close after posting")
    this.closeMenu()
  }

  componentDidUpdate(prevProps) {
    // Remove spinner
    if (prevProps.context.thisComponent.props.oRights.isPosting !== this.props.context.thisComponent.props.oRights.isPosting) {
      console.log("Component updated")
      this.setState({ isPosting: this.props.context.thisComponent.props.oRights.isPosting });
    }
  }

  render() {
    if (!this.state.canManage)
      return null
    if (this.state.level!==2)
      return null
    if (!this.state.hasChildren)
      return null
    return (
      <div className='oar-RowMenuContainer' tabIndex="0" onBlur={ () => this.closeMenu() }>
        <div className='oar-RowMenuButton' onClick={ () => this.toggleMenu() }>
          <Icons icon='ellipsis-v' classes="svg-inline--fa fa-w-20"/>
        </div>
        { (this.state.menuOpen) &&
          <div className='oar-RowMenuItems'>
            { this.state.isPosting && 
              <div className="oar-Modal oar-MenuSpinner"><div className="lds-dual-ring active"></div></div>
            }
            <div className='oar-RowMenuHeader'>
              <div className='oar-RowMenuTitle'>{ t('oar.budget_menu_title') }</div>
              <div className='oar-RowMenuInfoButton' onClick={ () => this.toggleMenuDescription() }>
                <Icons icon='question-circle' classes="svg-inline--fa fa-w-20"/>
              </div>
            </div>
            { this.state.showAddCreateToReadMenu &&
              <div className='oar-RowMenuItem' onClick={ () => this.menuClicked('addCreateToRead') }>
                <span style={{color: '#090', marginRight: '.5rem', fontSize: '.9rem'}}>
                  <Icons icon='plus' classes="svg-inline--fa fa-w-20"/>
                </span>
                { t('oar.budget_menu_allow_create') }
                { (false || this.state.menuDescriptionOpen) && <div className='oar-RowMenuItemDescription'>{ t('oar.budget_menu_allow_create_description') }</div> }
              </div>
            }
            { this.state.showReadOnlyMenu &&
              <div className='oar-RowMenuItem' onClick={ () => this.menuClicked('readOnly') }>
                <span style={{color: '#c00', marginRight: '.5rem', fontSize: '.9rem'}}>
                  <Icons icon='ban' classes="svg-inline--fa fa-w-20"/>
                </span>
                { t('oar.budget_menu_read_only') }
                { (false || this.state.menuDescriptionOpen) && <div className='oar-RowMenuItemDescription'>{ t('oar.budget_menu_read_only_description') }</div> }
              </div>
            }
          </div>
        }
      </div>
    );
  }
}