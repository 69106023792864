module.exports = {
  oar: {
    read: 'Read',
    update: 'Update',
    create: 'Create',
    delete: 'Delete',
    search: 'Search...',
    loadingFailure: 'Failed to load data',
    postingFailure: 'Failed update permissions',
    resources_missing: 'No resources have been created yet',
    budget_menu_title: 'Change sub-budget rights',
    budget_menu_allow_create_1: 'Add creation rights to reading rights',
    budget_menu_allow_create: 'Allow adding records',
    budget_menu_allow_create_description: 'Allow user to add and modify records in those sub-budgets he/she already has reading access to',
    budget_menu_read_only_1: 'Remove all except reading rights',
    budget_menu_read_only: 'Prohibit changes',
    budget_menu_read_only_description: 'Remove permissions to update, create and delete sub-budgets',
    allow_sb_rights_only: 'Allow changes at sub-budget level only'
  },
  erp: {
    loadingFailure: 'Failed to load data',
    veeraFields: 'VEERA fields',
    erpFields: 'ERP fields',
    techFields: 'Technical',
    search: "Search...",
    fields: {
      _raportis: {
        name: 'Sub-budget',
        tooltip: 'Sub-budget exists in report',
      },
      _filtered: {
        name: 'Changed',
        tooltip: 'Classification for the record has been changed upon loading into Veera',
      }
    },
    codeFormatMenu: {
      title: 'Code or Title',
      code: 'Code',
      name: 'Title',
      both: 'Code + Title'
    },
    header: {
      subBudget: 'SUB-BUDGET',
      moreInfo: 'More info...',
      lessInfo: 'Less info',
      erp: 'ERP',
      erpSource: 'GATEWAY SOURCE',
      erpTimestamp: 'ERP TIMESTAMP',
      erpConsumer: 'GATEWAY RULES',
    },
    buttonTooltip: {
      searchLocation: "Find in Report",
      min: "Minimize",
      half: "Half Screen",
      max: "Maximize",
      close: "Close",
      hideEmpty: 'Hide Empty Columns',
      columnWidth: 'Column Width',
      switchCodeName: 'Switch code/name for Veera claasification',
      removeFilters: 'Remove All Filters',
      excel: 'Excel',
      xml: 'XML',
      csv: 'CSV',
      copy: 'Copy'
    },
    toolbar: {
      view: 'VIEW',
      manageView: 'MANAGE VIEW',
      export: 'EXPORT'
    },
  }
}