import moment from 'moment'

import {  ERP_LOAD_SUCCESS, 
          ERP_LOAD_FAILURE, 
          ERP_LOAD_REQUEST, 
          ERP_RESET_ROWS, 
          ERP_SET_LOCALE, 
          ERP_SET_REPORTS, 
          ERP_EXPOSE_GRID_APIS, 
          ERP_CHANGE_COLUMN_DEFS, 
          ERP_CHANGE_VIEW, 
          ERP_SET_GLOBAL_CODE_EXPANDED, 
          ERP_SET_FILTER_PRESENT, 
          ERP_CLOSE_APP 
        } from '../actions/MainActionTypes'

import {currencyFormatter,checkFormatter} from '../../Core/agGridHelpers'
import erpConfig from '../../Core/constants/erpConfig'
import { setLanguage, t } from '../../Core/locale/locale.jsx'

const initialState = {
  erpId: erpConfig.default,
  reports: [],
  customTranslations: {},
  reportIndex: 0,
  locale: 'en',
  response: {},
  params: {},
  columnDefs: [],
  rowData: [],
  gridApi: null,
  gridColumnApi: null,
  gridOptions: null,
  isAnyFilterPresent: false,
  viewId: 0,
  subBudgetCodes: null,
  codeExpanded: 0
};

export default function MainReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {

    case ERP_LOAD_REQUEST:
//      console.log("Loading...")
      return Object.assign( {}, state, {
        isLoading: true,
        params: action.params
      })

    case ERP_LOAD_SUCCESS:
//      console.log(action.response)
//      console.log("Loading success")
      var erpId = Object.keys(erpConfig.list).includes( ((action.response.source.params || {}).options || {}).erp ) 
                    ? erpConfig.list[action.response.source.params.options.erp] 
                    : erpConfig.default
      var config = erpConfig[erpId]

      // TODO - dynamic?
//      const reportKeys2 = { osakond: 'units', tegevusala: 'functions', kuluobjekt: 'objects', projekt: 'projects' }
      const reportKeys = Object.keys(config.fields).filter( f => config.fields[f]['report_key'] )
                            .reduce( (acc,f) => {
                              acc[f]=config.fields[f]['report_key'];
                              return acc;
                            }, {})
      const modelKeys = Object.keys(config.fields).filter( f => config.fields[f]['model_key'] )
                            .reduce( (acc,f) => {
                              acc[f]=config.fields[f]['model_key'];
                              return acc;
                            }, {})

      var currencyColumns =Object.keys(config.fields).filter( f => config.fields[f].type==='currency')
      var numericColumns = Object.keys(config.fields).filter( f => config.fields[f].type==='numeric')
      var dateColumns = Object.keys(config.fields).filter( f => config.fields[f].type==='date')

      var columnDefs = Object.keys( (action.response.fields || {} ) )
        .filter( f => !(config.fields[f] && config.fields[f].drop) )
        .map( f => {
          // Replace original prefix ('erp_') with '*' in titles
          let headerName = f.replace( RegExp('^'+config.original_prefix),'*')
          // Replace header with custom translation (if present) for Veera columns
          if (Object.keys(modelKeys).includes(f) && state.customTranslations[modelKeys[f]])
            headerName = state.customTranslations[modelKeys[f]].toLowerCase()
          let col = {
            headerName: headerName,
            field: f, 
            colId: f,
          }
          
          if (reportKeys[f])
            col.reportKey = reportKeys[f]        // notify column menu about these fields having text from report
          
          switch ( (config.fields[f] || {}).type ) {
            case 'numeric':
              col.type = "numericColumn"
              col.filter = 'agNumberColumnFilter'
              break
            case 'currency':
              col.type = "numericColumn"
              col.filter = 'agNumberColumnFilter'
              col.valueFormatter = currencyFormatter[state.locale]
              col.getQuickFilterText = currencyFormatter[state.locale]
              break
            case 'date':
          }
          
          return col
        })
      // Create month columns for dates
      // NB: be sure to use colId with created cols to avoid _1 issue
      Object.keys( (action.response.fields || {} ) )
      .filter( f => (config.fields[f] || {}).type==='date' && config.fields[f].month_field_id )
      .forEach( f => {
        columnDefs.push( {
          headerName: config.fields[f].month_field_title, 
          field: config.fields[f].month_field_id, 
          colId: config.fields[f].month_field_id,
          hasData: true
        })
      })
      
      // Tech fields
      columnDefs.push( {headerName: t('erp.fields._raportis.name'),
                        field: '_raportis', 
                        colId: '_raportis', 
                        valueFormatter: checkFormatter, 
                        headerTooltip: t('erp.fields._raportis.tooltip'), 
                        headerClass: 'centered-header', 
                        width: 95, 
                        hide: true, 
                        hasData: true,
                        cellStyle: { 'text-align': "center" }},
                        
                        {headerName: t('erp.fields._filtered.name'),
                        field: '_filtered', 
                        colId: '_filtered', 
                        valueFormatter: checkFormatter, 
                        headerTooltip: t('erp.fields._filtered.tooltip'), 
                        headerClass: 'centered-header', 
                        width: 95, 
                        hide: true, 
                        hasData: true,
                        cellStyle: { 'text-align': "center" }},
                      )

      // This mutates response data
      var rowData = action.response.rows.map( row => {
        Object.keys(row).forEach( field => {
          // Ensure numeric value
          if (numericColumns.includes(field) || currencyColumns.includes(field)) {
            row[field] = Number(row[field])
          }

          // Set month column data
          if ( (config.fields[field] || {}).type==='date' && config.fields[field].month_field_id) {
            let dateField = config.fields[field]
            row[dateField.month_field_id] = moment( row[field], dateField.date_format || 'YYYY-MM-DD' ).format( dateField.month_field_format || "YYYY-MM" )
          }
          
          // Create hasData prop to show the column has data
          if (row[field] && row[field]!=="") {
            (columnDefs.find( col => col.field===field) || {}).hasData = true
          }
        })
        
        // See if the row's classificators match some subbudget
        let code = `'${row.tegevusala || ""}'-'${row.osakond || ""}'-'${row.kuluobjekt || ""}'`
        row['_raportis'] = (state.subBudgetCodes[action.params.columnIndex] || []).includes(code)
        
        // Mark rows affected by gate filters
        row['_filtered'] = Object.keys(row).some( f => /^_filter_/.test(f) )
        return row
      })
        
      
      return Object.assign( {}, state, {
        isLoading: false,
        success: true,
        response: action.response,
        reportIndex: action.params.columnIndex,
        columnDefs: [
          { headerName: t('erp.veeraFields'), headerClass: 'erp-veera-group-header',  children: columnDefs.filter( c => !c.field.match( RegExp('^'+config.original_prefix) ) && !c.field.match(/^_/)) },
          { headerName: t('erp.techFields'),    headerClass: 'erp-tech-group-header',   children: columnDefs.filter( c => c.field.match(/^_/)) },
          { headerName: t('erp.erpFields'),   headerClass: 'erp-erp-group-header',    children: columnDefs.filter( c => c.field.match( RegExp('^'+config.original_prefix) )) }
        ],
        // remove rows not matching any subbudget
        rowData: rowData.filter( row => row._raportis===true ),
        erpId: erpId
      })
      
    case ERP_LOAD_FAILURE:
      console.log("Loading failure")
      console.error(action.error)
      return Object.assign( {}, state, {
        isLoading: false,
        success: false,
        error: action.error,
      })
    
    case ERP_RESET_ROWS:
      return Object.assign( {}, state, {
        rowData: []
      })

    case ERP_SET_LOCALE:
      moment.locale( action.locale )
      setLanguage( action.locale )
      return Object.assign( {}, state, {
        locale: action.locale
      })

    case ERP_SET_REPORTS:
      let subBudgetCodes = []
      action.reports.forEach( (report,i) => subBudgetCodes.push([...new Set(report.records.map( r => r.version.sub_budget.code))]))
      return Object.assign( {}, state, {
        reports: action.reports,
        customTranslations: action.customTranslations,
        subBudgetCodes: subBudgetCodes
      })

    case ERP_EXPOSE_GRID_APIS:
      return Object.assign( {}, state, {
        gridApi: action.gridApi,
        gridColumnApi: action.gridColumnApi,
        gridOptions: action.gridOptions
      })

    case ERP_CHANGE_COLUMN_DEFS:
      return Object.assign( {}, state, {
        columnDefs: action.newDefs
      })

    case ERP_CHANGE_VIEW:
      return Object.assign( {}, state, {
        viewId: action.viewId,
      })
      
    case ERP_SET_GLOBAL_CODE_EXPANDED:
      return Object.assign( {}, state, {
        codeExpanded: action.codeExpanded,
      })

    case ERP_SET_FILTER_PRESENT:
      return Object.assign( {}, state, {
        isAnyFilterPresent: action.isAnyFilterPresent,
      })

    case ERP_CLOSE_APP:
      $("div[id^='ErpRecords-react-component']").parent().removeClass('active')
      $('.link-to-detail-row-active').removeClass('link-to-detail-row-active')
      $('.link-to-detail-cell-active').removeClass('link-to-detail-cell-active')
      return Object.assign( {}, state, {
        rowData: []
      })
      
    default:
      return state;
  }
}
