function formatNumber(number,precision=0) {
  return (Math.floor(number*10**precision)/10**precision).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

const currencyFormatter = {
  en: function currencyFormatter(params) {
        return formatNumber(params.value,2).toString();
      },
  et: function currencyFormatter(params) {
        return formatNumber(params.value,2).toString().replace('.', ',');
      },
}

function checkFormatter(params) {
  return (params.value) ? String.fromCharCode(0x2713) : '-'
}

function codeOnlyTitleGetter(params) {
  // Märge kui kood olemas aga teksti pole
  let missingText = ((params.data || {})[params.colDef.colId]) ? '-nimetus puudub-' : ''
  return params.context.report.labels[params.colDef.reportKey][(params.data || {})[params.colDef.colId]] || missingText
}

function codeAndTitleGetter(params) {
  let text = []
  text.push((params.data || {})[params.colDef.colId])
  text.push(params.context.report.labels[params.colDef.reportKey][(params.data || {})[params.colDef.colId]])
  return text.join(' ')
}

module.exports = { currencyFormatter, checkFormatter, codeOnlyTitleGetter, codeAndTitleGetter }