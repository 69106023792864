import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment'

import '../../Core/assets/styles/main.scss';
import '../assets/styles/styles.scss';

import '../../Core/assets/styles/fa-custom.scss';

import CodeItem from '../components/CodeItem';
import PanelButton from '../../Core/components/PanelButton';
import ToolbarContainer from '../containers/ToolbarContainer.jsx';

import { t } from '../../Core/locale/locale.jsx'
import erpConfig from '../../Core/constants/erpConfig'

export default class Header extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    erp: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.closeApp = this.closeApp.bind(this);
    this.state = {
      moreInfoVisible: false
    }
  }

  closeApp() {
    this.props.actions.closeApp()
  }

  scrollToParentRow() {
    let row = document.getElementsByClassName("link-to-detail-row-active")[0]
    if (row)
      row.scrollIntoView(false)
  }

  restorePanel() {
    $('.split-grid-bottom').first().css('height', Math.round(window.innerHeight/2) + 'px');
  }

  maximazePanel() {
    $('.split-grid-bottom').first().css('height', Math.round(window.innerHeight) + 'px'); // Relies on css max-height
  }

  minimazePanel() {
    $('.split-grid-bottom').first().css('height', '0px'); // Relies on css min-height
  }

  codeText(key,value) {
    return (((this.props.erp.reports[this.props.erp.reportIndex] || {}).labels || {})[key] || {})[value]
  }
  
  codeFormat(object, key) {
    return (object.hasOwnProperty(key)) ? (object[key] || '--') : "*"
  }
  
  toggleState = (item) => {
    this.setState( prevState => ({[item]: !prevState[item]}))
  }

  render() {
    var params = this.props.erp.params.query || {}
    var source_log = this.props.erp.response.source_log || {}
    var updatedTime = ((source_log.import || {}).log_time) ? moment(source_log.import.log_time).format('L') + ' ' + moment(source_log.import.log_time).format('LT') : "-"
    return (
      <div className="erp-HeaderDiv">
        { this.props.erp.isLoading && 
          <div className="erp-Modal" style={{fontSize: '3rem'}}><div className="lds-dual-ring active"></div></div>
        }
        <div className="erp-HeaderInfoRow">
          <span>{(this.props.erp.reports[this.props.erp.reportIndex] || {}).title}</span>
          <span className="erp-separator"></span> 
          <span>{(this.props.erp.reports[this.props.erp.reportIndex] || {}).name}</span>
        </div>
        
        <div style={{display: 'flex', flexWrap: 'nowrap', fontSize: '1.2em', borderBottom: '1px solid #ddd', marginLeft: '.5rem'}}>
          <span className="bold">
          {this.props.erp.params.row_text} 
          </span>
          <span className="erp-separator"></span> 
          <span className="mono bold">
          {this.props.erp.params.row_sum}
          </span>
        </div>
        
        <div className="erp-HeaderInfoRow">
          <CodeItem title={ t('erp.header.subBudget')+':'} text={this.props.erp.params.title || "*"} />
          <CodeItem title={(this.props.erp.customTranslations['unit'] || "").toUpperCase()+':'} code={this.codeFormat( params, 'osakond')} text={this.codeText('units',params.osakond)} />
          <CodeItem title={(this.props.erp.customTranslations['function'] || "").toUpperCase()+':'} code={this.codeFormat( params, 'tegevusala')} text={this.codeText('functions',params.tegevusala)} />
          <CodeItem title={(this.props.erp.customTranslations['item'] || "").toUpperCase()+':'} code={this.codeFormat( params, 'kuluobjekt')} text={this.codeText('objects',params.kuluobjekt)} />
          <CodeItem title={(this.props.erp.customTranslations['project'] || "").toUpperCase()+':'} code={this.codeFormat( params, 'projekt')} text={this.codeText('projects',params.projekt)} />
          <div onClick={() => this.toggleState('moreInfoVisible')} 
              className="erp-ToggleLink" 
              style={{marginLeft: '1rem'}}>
                {(this.state.moreInfoVisible) ? t('erp.header.lessInfo') : t('erp.header.moreInfo')}
          </div>
        </div>
        
        { this.state.moreInfoVisible && 
          <div className="erp-HeaderInfoRow">
            <CodeItem title={t('erp.header.erp')+':'} text={erpConfig[this.props.erp.erpId].title}/>
            <CodeItem title={t('erp.header.erpSource')+':'} text={(this.props.erp.response.source || {}).title}/>
            <CodeItem title={t('erp.header.erpTimestamp')+':'} text={updatedTime}/>
            <CodeItem title={t('erp.header.erpConsumer')+':'} text={(this.props.erp.response.consumer || {}).title}/>
          </div>
        }

        <PanelButton icon="search-location"  title={t('erp.buttonTooltip.searchLocation')}  style={{right: '6.5rem'}} onClick={this.scrollToParentRow}/>
        <PanelButton icon="window-minimize"  title={t('erp.buttonTooltip.min')}             style={{right: '5rem'}}   onClick={this.minimazePanel}/>
        <PanelButton icon="window-restore"   title={t('erp.buttonTooltip.half')}            style={{right: '3.5rem'}} onClick={this.restorePanel}/>
        <PanelButton icon="window-maximize"  title={t('erp.buttonTooltip.max')}             style={{right: '2rem'}}   onClick={this.maximazePanel}/>
        <PanelButton icon="times"            title={t('erp.buttonTooltip.close')}           style={{right: '.5rem'}}  onClick={this.closeApp}/>

        <ToolbarContainer />
      </div>
    );
  }
}
