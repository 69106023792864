import PropTypes from 'prop-types';
import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from "@ag-grid-enterprise/all-modules";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';


import {codeOnlyTitleGetter, codeAndTitleGetter} from '../../Core/agGridHelpers'
import erpConfig from '../../Core/constants/erpConfig'
import helpers from '../helpers/helpers';
import { t } from '../../Core/locale/locale.jsx'

import localeText from '../../Core/constants/aggridLocale'
import '../assets/styles/styles.scss';

export default class Table extends React.Component {
  componentStateChangedCallbacks = []
  
  static propTypes = {
    actions: PropTypes.object.isRequired,
    erp: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      gridOptions: {
        modules: AllModules,
        columnDefs: this.props.erp.columnDefs,
        groupHeaderHeight: 16,
        localeText: localeText[this.props.erp.locale],
        defaultColDef: {
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          sortable: true,
          filter: true,
          resizable: true,
          cellClassRules: {
            'excelDefault': params => true,
            'excelCurrency': function(params) {
              if (params.colDef.type==='numericColumn')
                return true
              return false
            },
            'excelString': function(params) {
              if (params.colDef.type!=='numericColumn')
                return true
              return false
            },
            
          }
        },
        rowClassRules: {
          "erp-not-in-report":  function(params) {
            return (params.data && !params.data._raportis)
          }
        },
        excelStyles: [
          {
            id: 'excelDefault',
            font: {
              fontName: 'Calibri',
              size: 11
            }
          },
          {
            id: 'header',
            font: {
              fontName: 'Calibri',
              size: 11
            }
          },
          { 
            id: 'excelString',
            dataType: 'string',
          },
          {
            id: 'excelDate',
          },
          {
            id: 'excelCurrency',
            dataType: 'number',
            numberFormat: { format: '#,##0.00' }
          },
        ],
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left"
            },
            { statusPanel: "agAggregationComponent" }
          ]
        }
      },
    }
  }

  componentDidMount() {
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.gridApi) {
      if (this.props.erp.isLoading) {
        this.gridApi.showLoadingOverlay()
      } else {
        this.gridApi.hideOverlay();
      }
    }
    // New view
    if (this.props.erp.viewId!==prevProps.erp.viewId) {
      this.changeView()
    }
    // New data
    if (this.props.erp.params!==prevProps.erp.params) {
      // Reset isAnyFilterPresent flag
      this.onFilterChanged()
      // For later execution to ensure data is loaded before changing view, doing autosize, etc.
      this.componentStateChangedCallbacks.push( this.changeView )
    }
    // Global code/text changed
    if (this.props.erp.codeExpanded!==prevProps.erp.codeExpanded) {
      this.setTextForCodes(true)
    }
  }

  changeView = () => {
    this.gridColumnApi.setPivotMode(false)
    this.gridColumnApi.resetColumnState()
    this.setTextForCodes()
    let erpType = this.props.erp.erpId
    let viewId = this.props.erp.viewId
    if (erpConfig[ erpType ].views[ viewId ] && typeof erpConfig[ erpType ].views[ viewId ].fn==='function')
      erpConfig[ erpType ].views[ viewId ].fn( this )
  }

  setTextForCodes = (forceChange=false) => {
    // Ignore if called with new data and code-only view
    if ( !(this.props.erp.codeExpanded>0 || forceChange) )
      return
    let colIds = this.props.erp.gridColumnApi.getAllColumns().filter(col => (col.userProvidedColDef || {}).reportKey).map( col => col.colId )
    var getters = [ null, codeOnlyTitleGetter, codeAndTitleGetter]
    var newColumnDefs = this.props.erp.columnDefs
    colIds.forEach( colId => {
      newColumnDefs = helpers.changeColumnDef( newColumnDefs, colId, {
        valueGetter: getters[this.props.erp.codeExpanded], 
        codeExpanded: this.props.erp.codeExpanded
      })
    })
    this.props.actions.changeColumnDefs(newColumnDefs)
    this.props.erp.gridApi.setColumnDefs(newColumnDefs)    
  }

  autoSizeAllColumns = () => {
    let allColumnIds = this.gridColumnApi.getAllGridColumns().map( c => c.colId ) 
    this.gridColumnApi.autoSizeColumns(allColumnIds, false);
  }

  onComponentStateChanged = params => {
    if (this.props.erp.rowData.length>0) {
      // Delayed functions executed here after table rendering is finished
      this.componentStateChangedCallbacks.forEach( (f,index) => {
        // call each f-n only once
        if (this.componentStateChangedCallbacks.indexOf(f)===index) {
          f()
        }
      })
      this.componentStateChangedCallbacks = []
    }
  };
  
  onFilterChanged = params => {
    this.props.actions.setFilterPresent(this.props.erp.gridApi.isAnyFilterPresent())
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.props.actions.exposeGridApis( this.gridApi, this.gridColumnApi, this.state.gridOptions )
  };
  
  onFirstDataRendered = params => {
    this.autoSizeAllColumns()
  };
  
  render() {
    return (
      <div
        className="ag-theme-balham"
        style={{
//        height: 'calc(100% - 56px)',
        width: '100vw' }}
      >
        <AgGridReact
          modules={this.state.gridOptions.modules}
          columnDefs={this.props.erp.columnDefs}
          defaultColDef={this.state.gridOptions.defaultColDef}
          rowData={this.props.erp.rowData}
          rowClassRules={this.state.gridOptions.rowClassRules}
          groupHeaderHeight={this.state.gridOptions.groupHeaderHeight}
          localeText={this.state.gridOptions.localeText}
          excelStyles={this.state.gridOptions.excelStyles}
          enableRangeSelection={true}
          sideBar={true}
          statusBar={this.state.gridOptions.statusBar}
          enableCharts={true}
          onGridReady={this.onGridReady}
          onComponentStateChanged={this.onComponentStateChanged}
          onFirstDataRendered={this.onFirstDataRendered}
          onFilterChanged={this.onFilterChanged}
          getMainMenuItems={this.getMainMenuItems.bind(this)}
          context={{ thisComponent: this, report: this.props.erp.reports[this.props.erp.reportIndex] }}
        >
        </AgGridReact>
      </div>      
    );
  }
  
  // Immutable change
  changeColumnDef = ( colId, params ) => {
    return this.props.erp.columnDefs.map( (cg) => {
      if (cg.children.some( c => c.colId===colId)) {
        return Object.assign( {}, cg, { 
              children: cg.children.map( (c) => {
                if (c.colId===colId) {
                  return Object.assign( {}, c, params )
                }
                return c
              })
        })
      }
      return cg
    })
  }
  
  getMainMenuItems(params) {
//    var props = this.props
    var that = this
    var props =params.context.thisComponent.props
    var colId = params.column.getId()
    var colDef = params.column.getColDef()
    var menuItems = params.defaultItems.slice(0);
    if (colDef.reportKey) {
      menuItems.push({
        name: t('erp.codeFormatMenu.title'),
        subMenu: [
          {
            name: t('erp.codeFormatMenu.code'),
            action: function() {
              let newColumnDefs = helpers.changeColumnDef( props.erp.columnDefs, colId,{
                valueGetter: null, 
                codeExpanded: 0
              })
              props.actions.changeColumnDefs(newColumnDefs)
              props.erp.gridApi.setColumnDefs(newColumnDefs)
            },
            checked: !(colDef.codeExpanded>0)
          },
          {
            name: t('erp.codeFormatMenu.name'),
            action: function() {
              let newColumnDefs = helpers.changeColumnDef( props.erp.columnDefs, colId,{
                valueGetter: codeOnlyTitleGetter,
                codeExpanded: 1
              })
              props.actions.changeColumnDefs(newColumnDefs)
              props.erp.gridApi.setColumnDefs(newColumnDefs)
            },
            checked: colDef.codeExpanded===1
          },
          {
            name: t('erp.codeFormatMenu.both'),
            action: function() {
              let newColumnDefs = helpers.changeColumnDef( props.erp.columnDefs, colId, {
                valueGetter: codeAndTitleGetter, 
                codeExpanded: 2
              })
              props.actions.changeColumnDefs(newColumnDefs)
              props.erp.gridApi.setColumnDefs(newColumnDefs)
            },
            checked: colDef.codeExpanded===2
          }
        ]
      });
    } // if reportKey
    return menuItems;
  }  
}

