import {  ERP_LOAD_SUCCESS, 
          ERP_LOAD_FAILURE, 
          ERP_LOAD_REQUEST, 
          ERP_RESET_ROWS, 
          ERP_SET_LOCALE, 
          ERP_SET_REPORTS, 
          ERP_EXPOSE_GRID_APIS, 
          ERP_CHANGE_COLUMN_DEFS, 
          ERP_CHANGE_VIEW, 
          ERP_SET_GLOBAL_CODE_EXPANDED, 
          ERP_SET_FILTER_PRESENT, 
          ERP_CLOSE_APP 
        } from './MainActionTypes'

export function loadSuccess(params, response) {
  return {
    type: ERP_LOAD_SUCCESS,
    params,
    response
  }
}
export function loadFailure(params, error) {
  return {
    type: ERP_LOAD_FAILURE,
    params,
    error
  }
}
export function loadRequest(params) {
  return {
    type: ERP_LOAD_REQUEST,
    params
  }
}
export function resetRows() {
  return {
    type: ERP_RESET_ROWS,
  }
}
export function setLocale(locale) {
  return {
    type: ERP_SET_LOCALE,
    locale
  }
}
export function setReports(reports,customTranslations) {
  return {
    type: ERP_SET_REPORTS,
    reports,
    customTranslations
  }
}
export function exposeGridApis(gridApi,gridColumnApi,gridOptions) {
  return {
    type: ERP_EXPOSE_GRID_APIS,
    gridApi,
    gridColumnApi,
    gridOptions
  }
}
export function changeColumnDefs(newDefs) {
  return {
    type: ERP_CHANGE_COLUMN_DEFS,
    newDefs
  }
}
export function changeView(viewId) {
  return {
    type: ERP_CHANGE_VIEW,
    viewId
  }
}
export function setGlobalCodeExpanded(codeExpanded) {
  return {
    type: ERP_SET_GLOBAL_CODE_EXPANDED,
    codeExpanded
  }
}
export function setFilterPresent(isAnyFilterPresent) {
  return {
    type: ERP_SET_FILTER_PRESENT,
    isAnyFilterPresent
  }
}
export function closeApp() {
  return {
    type: ERP_CLOSE_APP,
  }
}

