import et from './et.jsx';
import en from './en.jsx';

const language = {et: et, en: en}
var locale = 'et'

export const setLanguage = (l) => {
  locale = l
}

export const t = (path) => {
  try {
    return path.split('.').reduce((o,i)=>o[i], language[locale])
  } catch(e) {
    return '***'
  }
}


