module.exports = {
  oar: {
    read: 'Lugemine',
    update: 'Muutmine',
    create: 'Lisamine',
    delete: 'Kustutamine',
    search: 'Otsi...',
    loadingFailure: 'Andmete laadimine ebaõnnestus',
    postingFailure: 'Õiguste muutmine ebaõnnestus',
    resourcesMissing: 'Ressursse pole loodud',
    budget_menu_title: 'Muuda alaeelarvete õiguseid',
    budget_menu_allow_create_1: 'Täienda lugemisõigust lisamisõigusega',
    budget_menu_allow_create: 'Luba kirjete lisamine',
    budget_menu_allow_create_description: 'Luba kasutajal lisada ja muuta kirjeid neis alaeelarvetes, millele tal on hetkel antud lugemisõigus',
    budget_menu_read_only_1: 'Kustuta kõik peale lugemisõiguste',
    budget_menu_read_only: 'Keela muutmine',
    budget_menu_read_only_description: 'Eemalda alaeelarvetelt muutmis-, lisamis- ja kustutamisõigused',
    allow_sb_rights_only: 'Luba ainult alaeelarvete õiguste muutmine'
  },
  erp: {
    loadingFailure: 'Andmete laadimine ebaõnnestus',
    veeraFields: 'VEERA veerud',
    erpFields: 'ERP veerud',
    techFields: 'Tehniline',
    search: "Otsi...",
    fields: {
      _raportis: {
        name: 'Alaeelarve',
        tooltip: 'Vastav alaeelarve on raportis olemas',
      },
      _filtered: {
        name: 'Muudetud',
        tooltip: 'Kirje klassifikatorid Veerasse laadimisel muudetud',
      }
    },
    codeFormatMenu: {
      title: 'Kood või nimetus',
      code: 'Kood',
      name: 'Nimetus',
      both: 'Kood + nimetus'
    },
    header: {
      subBudget: 'ALAEELARVE',
      moreInfo: 'Lisainfo...',
      lessInfo: 'Peida lisainfo',
      erp: 'ERP',
      erpSource: 'ALLIKAS ANDMELAOS',
      erpTimestamp: 'ANDMED RAAMATUPIDAMISEST SEISUGA',
      erpConsumer: 'ANDMEREEGLID',
    },
    buttonTooltip: {
      searchLocation: "Otsi raportist",
      min: "Minimeeri",
      half: "Pool ekraani",
      max: "Maksimeeri",
      close: "Sulge",
      hideEmpty: 'Peida andmeteta veerud',
      columnWidth: 'Veerulaius',
      switchCodeName: 'Veera klassifikaatorite kood/nimetus',
      removeFilters: 'Kõik filtrid maha',
      excel: 'Excel',
      xml: 'XML',
      csv: 'CSV',
      copy: 'Kopeeri'
    },
    toolbar: {
      view: 'VAADE',
      manageView: 'VAATE KOHENDAMINE',
      export: 'EKSPORT'
    }
  }
}