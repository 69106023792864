import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../components/Header';

import * as MainActions from '../actions/MainActions';

const HeaderContainer = ({ actions, erp, railsContext }) => (
  <Header {...{ actions, erp, railsContext }} />
);

HeaderContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  erp: PropTypes.object.isRequired,
  railsContext: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    erp: state.erp,
    railsContext: state.railsContext,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({...MainActions}, dispatch) };
}

// Don't forget to actually use connect!
export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
