module.exports = {
  et: {
        // for filter panel
        page: 'Leht',
        more: 'Veel',
        to: 'kuni',
        of: '/',
        next: 'Järgmine',
        last: 'Viimane',
        first: 'Esimene',
        previous: 'Eelmine',
        loadingOoo: 'Laen andmeid ...',

        // for set filter
        selectAll: 'Kõik',
        searchOoo: 'Otsi...',
        blanks: 'Tühi',

        // for number filter and text filter
        filterOoo: 'Filter...',
        applyFilter: 'Rakenda filter...',
        equals: 'Võrdub',
        notEqual: 'Ei võrdu',

        // for number filter
        lessThan: 'Väiksem kui',
        greaterThan: 'Suurem kui',
        lessThanOrEqual: 'Väiksem või võrdne',
        greaterThanOrEqual: 'Suurem või võrdne',
        inRange: 'Vahemikus',

        // for text filter
        contains: 'Sisaldab',
        notContains: 'Ei sisalda',
        startsWith: 'Algus on',
        endsWith: 'Lõpp on',

        // filter conditions
        andCondition: 'JA',
        orCondition: 'VÕI',

        // the header of the default group column
        group: 'Grupp',

        // tool panel
        columns: 'Veerud',
        filters: 'Filtrid',
        rowGroupColumns: 'Grupiveerud',
        rowGroupColumnsEmptyMessage: 'Grupeeritavad veerud siia',
        valueColumns: 'Arvutatavad veerud',
        pivotMode: 'Pivot',
        groups: 'Grupid',
        values: 'Väärtused',
        pivots: 'Pivot-veerud',
        valueColumnsEmptyMessage: 'Arvutatavad veerud siia',
        pivotColumnsEmptyMessage: 'Pivot-veerud siia',
        toolPanelButton: 'Abipaneel',

        // other
        noRowsToShow: 'Ridu pole',
        enabled: 'Lubatud',

        // enterprise menu
        pinColumn: 'Pin',
        valueAggregation: 'Arvuta',
        autosizeThiscolumn: 'Autolaius',
        autosizeAllColumns: 'Kõigi veergude autolaius',
        groupBy: 'Grupeeri',
        ungroupBy: 'Grupeeri lahti',
        resetColumns: 'Taasta algne paigutus',
        expandAll: 'Read lahti',
        collapseAll: 'Read kinni',
        toolPanel: 'Abipaneel',
        export: 'Eksport',
        csvExport: 'CSV',
        excelExport: 'Excel (.xlsx)',
        excelXmlExport: 'Excel (.xml)',

        // enterprise menu (charts)
        pivotChartAndPivotMode: 'Pivot graafik & Pivot režiim',
        pivotChart: 'Pivot graafik',
        chartRange: 'Graafik',
/*
        columnChart: 'Column',
        groupedColumn: 'Grouped',
        stackedColumn: 'Stacked',
        normalizedColumn: '100% Stacked',

        barChart: 'Bar',
        groupedBar: 'Grouped',
        stackedBar: 'Stacked',
        normalizedBar: '100% Stacked',

        pieChart: 'Pie',
        pie: 'Pie',
        doughnut: 'Doughnut',

        line: 'Line',

        xyChart: 'X Y (Scatter)',
        scatter: 'Scatter',
        bubble: 'Bubble',

        areaChart: 'Area',
        area: 'Area',
        stackedArea: 'Stacked',
        normalizedArea: '100% Stacked',
*/
        // enterprise menu pinning
        pinLeft: 'Vasakule <<',
        pinRight: 'Paremale >>',
        noPin: 'Pin maha',

        // enterprise menu aggregation and status bar
        sum: 'Sum',
        min: 'Min',
        max: 'Max',
        none: 'None',
        count: 'Count',
        average: 'Avg',
        avg: 'Avg',
        filteredRows: 'Filtreeritud',
        selectedRows: 'Valitud',
        totalRows: 'Kokku ridu',
        totalAndFilteredRows: 'Ridu',

        // standard menu
        copy: 'Kopeeri',
        copyWithHeaders: 'Kopeeri veerupäistega',
        ctrlC: 'ctrl C',
        paste: 'Kleebi',
        ctrlV: 'ctrl V',

        // charts
/*
        pivotChartTitle: 'laPivot Chart',
        rangeChartTitle: 'laRange Chart',
        settings: 'laSettings',
        data: 'laData',
        format: 'laFormat',
        categories: 'laCategories',
        series: 'laSeries',
        xyValues: 'laX Y Values',
        paired: 'laPaired Mode',
        axis: 'laAxis',
        color: 'laColor',
        thickness: 'laThickness',
        xRotation: 'laX Rotation',
        yRotation: 'laY Rotation',
        ticks: 'laTicks',
        width: 'laWidth',
        length: 'laLength',
        padding: 'laPadding',
        chart: 'laChart',
        title: 'laTitle',
        background: 'laBackground',
        font: 'laFont',
        top: 'laTop',
        right: 'laRight',
        bottom: 'laBottom',
        left: 'laLeft',
        labels: 'laLabels',
        size: 'laSize',
        minSize: 'laMinimum Size',
        maxSize: 'laMaximum Size',
        legend: 'laLegend',
        position: 'laPosition',
        markerSize: 'laMarker Size',
        markerStroke: 'laMarker Stroke',
        markerPadding: 'laMarker Padding',
        itemPaddingX: 'laItem Padding X',
        itemPaddingY: 'laItem Padding Y',
        strokeWidth: 'laStroke Width',
        offset: 'laOffset',
        offsets: 'laOffsets',
        tooltips: 'laTooltips',
        callout: 'laCallout',
        markers: 'laMarkers',
        shadow: 'laShadow',
        blur: 'laBlur',
        xOffset: 'laX Offset',
        yOffset: 'laY Offset',
        lineWidth: 'laLine Width',
        normal: 'laNormal',
        bold: 'laBold',
        italic: 'laItalic',
        boldItalic: 'laBold Italic',
        predefined: 'laPredefined',
        fillOpacity: 'laFill Opacity',
        strokeOpacity: 'laLine Opacity',
        columnGroup: 'laColumn',
        barGroup: 'laBar',
        pieGroup: 'laPie',
        lineGroup: 'laLine',
        scatterGroup: 'laScatter',
        areaGroup: 'laArea',
        groupedColumnTooltip: 'laGrouped',
        stackedColumnTooltip: 'laStacked',
        normalizedColumnTooltip: 'la100% Stacked',
        groupedBarTooltip: 'laGrouped',
        stackedBarTooltip: 'laStacked',
        normalizedBarTooltip: 'la100% Stacked',
        pieTooltip: 'laPie',
        doughnutTooltip: 'laDoughnut',
        lineTooltip: 'laLine',
        groupedAreaTooltip: 'laGrouped',
        stackedAreaTooltip: 'laStacked',
        normalizedAreaTooltip: 'la100% Stacked',
        scatterTooltip: 'laScatter',
        bubbleTooltip: 'laBubble',
        noDataToChart: 'laNo data available to be charted.',
        pivotChartRequiresPivotMode: 'laPivot Chart requires Pivot Mode enabled.'
*/
  }
}