import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Table from '../components/Table';

import * as MainActions from '../actions/MainActions';

const TableContainer = ({ actions, erp, railsContext }) => (
  <Table {...{ actions, erp, railsContext }} />
);
TableContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  erp: PropTypes.object.isRequired,
  railsContext: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    erp: state.erp,
    railsContext: state.railsContext,
  };
}

function mapDispatchToProps(dispatch) {
//  return { actions: bindActionCreators(SampleActions, dispatch) };
  return { actions: bindActionCreators(MainActions, dispatch) };
}

// Don't forget to actually use connect!
export default connect(mapStateToProps, mapDispatchToProps)(TableContainer);
