export const ERP_LOAD_SUCCESS             ='ERP_LOAD_SUCCESS'
export const ERP_LOAD_FAILURE             ='ERP_LOAD_FAILURE'
export const ERP_LOAD_REQUEST             ='ERP_LOAD_REQUEST'
export const ERP_RESET_ROWS               ='ERP_RESET_ROWS'
export const ERP_SET_LOCALE               ='ERP_SET_LOCALE'
export const ERP_SET_REPORTS              ='ERP_SET_REPORTS'
export const ERP_EXPOSE_GRID_APIS         ='ERP_EXPOSE_GRID_APIS'
export const ERP_CHANGE_COLUMN_DEFS       ='ERP_CHANGE_COLUMN_DEFS'
export const ERP_CHANGE_VIEW              ='ERP_CHANGE_VIEW'
export const ERP_SET_GLOBAL_CODE_EXPANDED ='ERP_SET_GLOBAL_CODE_EXPANDED'
export const ERP_SET_FILTER_PRESENT       ='ERP_SET_FILTER_PRESENT'
export const ERP_CLOSE_APP                ='ERP_CLOSE_APP'
