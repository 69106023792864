import React from 'react';

import Icons from '../assets/icons/fa-custom.jsx';
import '../assets/styles/main.scss';

export default class PanelButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <span className="PanelButton" style={this.props.style} onClick={this.props.onClick} title={this.props.title}>
          <Icons icon={this.props.icon} classes="svg-inline--fa fa-w-18"/>
        </span>    
    );
  }
  
}
