import mainReducer from './MainReducer';
import railsContextReducer from '../../Core/reducers/RailsContextReducer';


// This is how you do a directory of reducers.
// The `import * as reducers` does not work for a directory, but only with a single file
export default {
  erp: mainReducer,
  railsContext: railsContextReducer
};
