// Immutable change
exports.changeColumnDef = ( oldDefs, colId, params ) => {
  return oldDefs.map( (cg) => {
    if (cg.children.some( c => c.colId===colId)) {
      return Object.assign( {}, cg, { 
            children: cg.children.map( (c) => {
              if (c.colId===colId) {
                return Object.assign( {}, c, params )
              }
              return c
            })
      })
    }
    return cg
  })
}

