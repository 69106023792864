/* global fetch */
/* global $ */

function checkResponseStatus(response) {
  if ( response.ok )
    return response;
  else
    throw new Error("Server responded with: " + response.status+" "+response.statusText);
}

function setRoleValues( response, actions ) {
  response.results.forEach( result => {
    actions.setRole( result )
  })
}

export function postChanges( roles, occupationId, actions ) {
  // TODO - destroy role with all false. Later, when second role module is also brought to react, need changes in controller
  // (Controller needs to hand back role id after roll creation, component needs to store it besides role for deletion request?)

  return new Promise( async (resolve,reject) => {
  
    var url = '/occupations/' + occupationId + '/roles'
    var body = JSON.stringify( {role: {roles: roles}} )
    var token = $('meta[name="csrf-token"]').attr('content')
  
    fetch( url, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },      
      credentials: 'include',
      body: body
    })
      .then( checkResponseStatus )
      .then( response => response.json() )
      .then( response => setRoleValues(response, actions) )
      .then( response => actions.postSuccess() )
      .then( () => actions.redrawInheritance() )
      .then( () => resolve() )
      .catch( error => {
        actions.postFailure(error)
        return resolve()
      }) 
      
  })

}
