// import ReactOnRails from 'react-on-rails';
window.ReactOnRails = window.ReactOnRails || require('react-on-rails').default;

import {LicenseManager} from "@ag-grid-enterprise/core";
import licenceKey from '../bundles/Core/constants/aggridLicence'
LicenseManager.setLicenseKey(licenceKey);

//import ReportTable from '../bundles/ReportTable/app/App';
import OccupationAccessRights from '../bundles/OccupationAccessRights/app/App';
import ErpRecords from '../bundles/ErpRecords/app/App';
import SharedStore from '../bundles/Core/stores/SharedStore';

// This is how react_on_rails can see the BudgetEntry in the browser.
ReactOnRails.register({
  OccupationAccessRights,
  ErpRecords,
//  ReportTable
});

ReactOnRails.registerStore({
  SharedStore,
});

ReactOnRails.setOptions({
  traceTurbolinks: false,
});
