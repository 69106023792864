import React from 'react';

import PanelButton from './PanelButton';

import '../assets/styles/main.scss';

export default class MessagePanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="MessagePanelContainer">
        <div className="MessagePanelBackground"></div>
        <div className="MessagePanel">
          <div className="MessagePanelHeader">{this.props.title}</div>
          <div className="MessagePanelBody">{this.props.message}</div>
          { (typeof this.props.close !== 'undefined') &&
            <PanelButton icon="times" title="Sulge" style={{right: '.4rem'}} onClick={this.props.close}/>
          }
        </div>
      </div>
    );
  }
  
}
