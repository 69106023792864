/* global fetch */
/* global $ */

import React, { Component } from 'react';

import Icons from '../../Core/assets/icons/fa-custom.jsx';
import '../../Core/assets/styles/fa-custom.scss';
import '../assets/styles/styles.scss';

const permissionIcons = {c: "plus", r: "eye", u: "pencil-alt", d: "trash-alt"}

export default class PermissionHeader extends Component {
  constructor(props) {
    super(props);
    this.parentProps = this.props.context.thisComponent.props
    this.state = {
      filteredPermission: this.parentProps.oRights.filteredPermission
    };

    props.column.addEventListener('filterChanged', this.onColumnFilterChanged.bind(this));
  }

  componentWillUnmount() {
    this.props.column.removeEventListener('filterChanged', this.onColumnFilterChanged);
  }  

  onColumnFilterChanged() {
    this.setState( {filteredPermission: this.props.context.thisComponent.props.oRights.filteredPermission} )
  }

  toggleFilter() {
    let permission = this.props.column.colId
    if (permission===this.state.filteredPermission)
      this.parentProps.actions.setFilter(null)
    else
      this.parentProps.actions.setFilter(permission)
  }
  
  render() {
    let displayTitle = this.props.displayName
    let toolTip = this.props.displayName
    let columnFiltered = this.state.filteredPermission===this.props.column.colId
    return (
      <div className="ag-cell-label-container oar-permissionHeader" onClick={() => this.toggleFilter()}>
        <div className="ag-header-cell-label">
          <span className="ag-header-cell-text" title={toolTip}>
            {displayTitle}
          </span>
          <span className={`ag-header-icon ag-header-label-icon ag-filter-icon ${(!columnFiltered) ? "ag-hidden" : ""}`}>
            <span className="ag-icon ag-icon-filter"></span>
          </span>
        </div>
      </div>
    );
  }
}