/* global fetch */
/* global $ */
import moment from 'moment'

import PropTypes from 'prop-types';
import React from 'react';

import { t } from '../../Core/locale/locale.jsx'

import TableContainer from '../containers/TableContainer.jsx';
import HeaderContainer from '../containers/HeaderContainer.jsx';
import MessagePanel from '../../Core/components/MessagePanel.jsx';

import '../../Core/assets/styles/helper.scss';

export default class Main extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    erp: PropTypes.object.isRequired,
    railsContext: PropTypes.object.isRequired
  };

  log(...text) {
    this.props.railsContext.railsEnv==='development' && console.log(moment().format('HH:mm:ss'),...text)
  }
  
  constructor(props) {
    super(props);
    this.setLocale()
  }

  setLocale() {
    let locale = $('body').data('locale') || 'en'
    this.props.actions.setLocale( locale )
  }

  checkResponseStatus(response) {
    if ( response.ok )
      return response;
    else
      throw new Error("Gateway responded with: " + response.status+" "+response.statusText);
  }

  fetchData(params) {
    const { actions } = this.props
    actions.resetRows()
    actions.loadRequest(params)

    if (Object.keys(params).length===0 || !params.api_detail_url || !params.api_secret) {
      actions.loadFailure(params, "no url/key")
      return
    }

    let url = params.api_detail_url
    let headers = {
      'x-resource-api-secret': params.api_secret,
      'x-resource-api-user': this.props.railsContext.currentUser.email,
      'Content-Type': 'application/json'
    }
    params.query['allfields']="true"
    params.query['limit'] = params.query['limit'] || 10000
//    let qs = Object.keys(params.query || {}).map( key => key+'=' + encodeURIComponent(params.query[key])).join('&')
//    url += (/\?/.test(url)) ? '&'+qs : '?'+qs
    this.log(url)

    fetch( url, { 
      method: 'POST', 
      headers: headers, 
      body: JSON.stringify(params.query) 
    })
      .then( this.checkResponseStatus )
      .then( response => response.json() )
      .then( response => actions.loadSuccess(params, response) )
//      .then( response => {throw new Error("Katkestus")} )
      .catch( error => actions.loadFailure(params, error) )
  }

  openApp() {
    $("div[id^='ErpRecords-react-component']").parent().addClass('active')
  }

  buildRequestParams($that, selected, reports, subBudgetCodes) {
    let columnIndex = $that.parent().index()-1
    if (columnIndex<0)
      return null

    let $sumCell = $that.parent()
    let size = $sumCell.data('size') || 0
    if (size===0) {
      return
    }

    $('.link-to-detail-row-active').removeClass('link-to-detail-row-active')
    $that.parents('tr').addClass('link-to-detail-row-active')
    $('.link-to-detail-cell-active').removeClass('link-to-detail-cell-active')
    $that.parents('td').addClass('link-to-detail-cell-active')

    let detailParams = {query:{}}
    let recordId = (""+($sumCell.data('records') || "")).split(',')[0]
    let record = reports[columnIndex].records.find( r => r.id==recordId )

    if (selected[3])
      detailParams.query['konto'] = record.account.number
    if (selected[1] || selected[5])
      detailParams.query['tegevusala'] = record.version.sub_budget.function
    if (selected[1] || selected[6])
      detailParams.query['osakond'] = record.version.sub_budget.unit
    if (selected[1] || selected[7])
      detailParams.query['kuluobjekt'] = record.version.sub_budget.object
    if (selected[8])
      detailParams.query['projekt'] = (record.project) ? record.project.code : ""
    if (selected[1])
      detailParams['title'] = record.version.sub_budget.name

    detailParams.query['__sbc'] = subBudgetCodes[columnIndex]

    detailParams['columnIndex'] = columnIndex
    detailParams['api_secret'] = reports[columnIndex].api_secret
    detailParams['api_detail_url'] = reports[columnIndex].erp_api_detail_url
    detailParams['row_text'] = $that.parents('tr').find('td').first().text()
    detailParams['row_sum'] = $that.parent().find('span.input').first().text()

    return detailParams
  }
  
  componentDidMount() {
    // Init callback functions to be used from outside of React
    window.reportsToDetailTable = (reports,customTranslations) => {
      this.log("reports recieved",reports)
      this.props.actions.setReports( reports, customTranslations )
      // Cache reports and translations on global for turbolinks switching between report and input view (budgets vs sub_budgets)
      window.erp_reports = reports
      window.erp_customTranslations = customTranslations
    }
    window.showDetailsFromGateway = ($that, selected) => {
      let reports = this.props.erp.reports

      // Restore from global cache after turbolinks has lost report data
      if (this.props.erp.reports.length===0 && (window.erp_reports || []).length>0) {
        this.props.actions.setReports( window.erp_reports, window.erp_customTranslations )
        reports = window.erp_reports
      }
      
      let subBudgetCodes = this.props.erp.subBudgetCodes
      
      if ( (reports || []).length===0 ) {
        console.log("No report data")
        return
      }

      if ( (subBudgetCodes || []).length===0 ) {
        console.log("No sub-budgets")
        return
      }

      if (this.props.erp.gridColumnApi) {
        const detailParams = this.buildRequestParams($that, selected, reports, subBudgetCodes)
        if (detailParams) {
          this.openApp()
          this.fetchData( detailParams )
        } else {
          console.log("No params")
        }
      } else {
        console.log("Grid not ready")
      }
    };
  }
  
  componentDidUpdate(prevProps, prevState) {
    // Temporary
    window.erp = this.props.erp
  }

  render() {
    return (
      <div className="erp-MainDiv">
        { this.props.erp.success===false && !this.props.erp.isLoading &&
          <MessagePanel title={t('erp.loadingFailure')} message={this.props.erp.error.message} close={this.props.actions.closeApp} />
        }
        <HeaderContainer />
        <TableContainer />
      </div>      
    );
  }
}

