export const OAR_LOAD_SUCCESS           ='OAR_LOAD_SUCCESS'
export const OAR_LOAD_FAILURE           ='OAR_LOAD_FAILURE'
export const OAR_LOAD_REQUEST           ='OAR_LOAD_REQUEST'

export const OAR_UPDATE                 ='OAR_UPDATE'
export const OAR_UPDATE_ROLES_ONLY      ='OAR_UPDATE_ROLES_ONLY'
export const OAR_SET_ROLE               ='OAR_SET_ROLE'

export const OAR_REDRAW_INHERITANCE     ='OAR_REDRAW_INHERITANCE'
export const OAR_EXPOSE_GRID_APIS       ='OAR_EXPOSE_GRID_APIS'

export const OAR_POST_SUCCESS           ='OAR_POST_SUCCESS'
export const OAR_POST_FAILURE           ='OAR_POST_FAILURE'
export const OAR_POST_REQUEST           ='OAR_POST_REQUEST'

export const OAR_CLEAR_ERRORS           ='OAR_CLEAR_ERRORS'
export const OAR_SET_LOCALE             ='OAR_SET_LOCALE'

export const OAR_SET_FILTER             ='OAR_SET_FILTER'
export const OAR_TOGGLE_LEVEL_LOCK      ='OAR_TOGGLE_LEVEL_LOCK'
