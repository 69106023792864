import {  OAR_LOAD_SUCCESS, 
          OAR_LOAD_FAILURE, 
          OAR_LOAD_REQUEST, 
          OAR_UPDATE, 
          OAR_UPDATE_ROLES_ONLY,
          OAR_SET_ROLE,
          OAR_REDRAW_INHERITANCE, 
          OAR_EXPOSE_GRID_APIS,
          OAR_POST_REQUEST, 
          OAR_POST_SUCCESS, 
          OAR_POST_FAILURE, 
          OAR_CLEAR_ERRORS, 
          OAR_SET_LOCALE, 
          OAR_SET_FILTER,
          OAR_TOGGLE_LEVEL_LOCK
        } from './MainActionTypes'

export function loadRequest(params) {
  return {
    type: OAR_LOAD_REQUEST,
    params
  }
}
export function loadSuccess() {
  return {
    type: OAR_LOAD_SUCCESS,
  }
}
export function loadFailure(params, error) {
  return {
    type: OAR_LOAD_FAILURE,
    params,
    error
  }
}
export function update(params,response) {
  return {
    type: OAR_UPDATE,
    params,
    response
  }
}
export function updateRolesOnly(params,response) {
  return {
    type: OAR_UPDATE_ROLES_ONLY,
    params,
    response
  }
}
export function setRole(role) {
  return {
    type: OAR_SET_ROLE,
    role
  }
}

export function redrawInheritance() {
  return {
    type: OAR_REDRAW_INHERITANCE,
  }
}
export function exposeGridApis(gridApi,gridColumnApi,gridOptions) {
  return {
    type: OAR_EXPOSE_GRID_APIS,
    gridApi,
    gridColumnApi,
    gridOptions
  }
}
export function postRequest() {
  return {
    type: OAR_POST_REQUEST,
  }
}
export function postSuccess() {
  return {
    type: OAR_POST_SUCCESS,
  }
}
export function postFailure(error) {
  return {
    type: OAR_POST_FAILURE,
    error
  }
}
export function clearErrors() {
  return {
    type: OAR_CLEAR_ERRORS,
  }
}
export function setLocale(locale) {
  return {
    type: OAR_SET_LOCALE,
    locale: locale
  }
}
export function setFilter(permission) {
  return {
    type: OAR_SET_FILTER,
    permission: permission
  }
}
export function toggleLevelLock() {
  return {
    type: OAR_TOGGLE_LEVEL_LOCK
  }
}
