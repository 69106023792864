import React from 'react';

import '../assets/styles/styles.scss';

export default class CodeItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="erp-HeaderInfoItem">
        <div>{this.props.title}</div>
        <div className="erp-ItemBadge">
          { (this.props.code || "").length>0 && <span className="mono bold">{this.props.code}</span> }
          { (this.props.text || "").length>0 && <span className="bold"> {this.props.text}</span> }
        </div>
      </div>
    );
  }
  
}
