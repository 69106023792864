/* global fetch */
/* global $ */

import moment from 'moment'

import PropTypes from 'prop-types';
import React from 'react';

import { t } from '../../Core/locale/locale.jsx'

import TableContainer from '../containers/TableContainer.jsx';
import ToolbarContainer from '../containers/ToolbarContainer.jsx';
import MessagePanel from '../../Core/components/MessagePanel.jsx';

export default class Main extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    oRights: PropTypes.object.isRequired,
    railsContext: PropTypes.object.isRequired
  };

  log(...text) {
    this.props.railsContext.railsEnv==='development' && console.log(moment().format('HH:mm:ss'),...text)
  }
  
  constructor(props) {
    super(props);
    this.setLocale()
  }

  setLocale() {
    let locale = $('body').data('locale') || 'en'
    this.props.actions.setLocale( locale )
  }
  
  checkResponseStatus(response) {
    if ( response.ok ) {
      return response;
    } else {
      throw new Error("Server responded with: " + response.status+" "+response.statusText);
    }
  }

  fetchData(params) {
    const { actions } = this.props
    var updateAction = (params.budget_document_id===this.props.oRights.documentId) ? actions.updateRolesOnly : actions.update

    actions.loadRequest(params)

    let url = '/organizations/'+params.organization_id+'/access_rights/'

    let method = 'POST'
    let nosb = (params.budget_document_id===this.props.oRights.documentId) ? 1 : null
    let body = JSON.stringify( 
      {
        budget_document_id: params.budget_document_id,
        occupation_id: params.occupation_id,
        nosb: nosb
      })
    let token = $('meta[name="csrf-token"]').attr('content')

    var that = this
    fetch( url, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },      
      credentials: 'include',
      body: body
    })
      .then( that.checkResponseStatus )
      .then( response => response.json() )
      .then( response => updateAction(params,response) )
      .then( response => actions.loadSuccess() )
      .then( response => actions.setFilter(null) )
      .then( () => actions.redrawInheritance() )
      .catch( error => actions.loadFailure(params, error) )

  }

  componentDidMount() {
    // Init callback functions to be used from outside of React
    window.renderOccupationRights = (params) => {
//      this.log("Data received",params)
//      if (params.budget_document_id && params.occupation_id)
      if (params.organization_id && params.occupation_id)
        this.fetchData(params)
    }
    
  }
  
  componentDidUpdate(prevProps, prevState) {
    window.oRights = this.props.oRights
  }

  render() {
    return (
      <div className="occupationRights-MainDiv" style={{height: "100%", position: 'relative'}}>
        { this.props.oRights.success===false && !this.props.oRights.isLoading &&
          <MessagePanel title={ t('oar.loadingFailure') } message={this.props.oRights.error.message} />
        }
        { this.props.oRights.postingSuccess===false && !this.props.oRights.isPosting &&
          <MessagePanel title={ t('oar.postingFailure') } message={this.props.oRights.postingError.message} close={this.props.actions.clearErrors}  />
        }
        <h3>{ this.props.oRights.params.occupation_title || t('oar.resourcesMissing') }</h3>
        { !this.props.oRights.loaded && this.props.oRights.params.occupation_title && this.props.oRights.postingSuccess!==false && this.props.oRights.success!==false &&
            <div className="oar-Modal" style={{fontSize: '3rem'}}><div className="lds-dual-ring active"></div></div>
        }
        { this.props.oRights.loaded &&
            <ToolbarContainer />
        }
        { this.props.oRights.loaded &&
            <TableContainer />
        }
      </div>      
    );
  }
}

