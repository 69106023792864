import React, { Component } from 'react';

import { postChanges } from '../utils';

import Icons from '../../Core/assets/icons/fa-custom.jsx';
import '../../Core/assets/styles/fa-custom.scss';
import '../assets/styles/styles.scss';

const permissionIcons = {c: "plus", r: "eye", u: "pencil-alt", d: "trash-alt"}

export default class PermissionRenderer extends Component {
  constructor(props) {
    super(props);
    this.parentProps = this.props.context.thisComponent.props
    this.state = {
      icon: permissionIcons[this.props.colDef.field],
      permissionClass: this.permissionClass(),
      isPosting: this.parentProps.oRights.isPosting,
      canManage: this.parentProps.railsContext.currentUser.currentOccupation.personnel_manage || this.parentProps.railsContext.currentUser.is_admin
    };
  }

  permissionClass() {
    if (this.props.value) return "oar-permitted";
    if (this.props.data['inherited_'+this.props.colDef.field]) return "oar-inherited";
    return "oar-missing"
  }

  togglePermission() {
    if (!this.state.canManage)
      return
    if (this.props.context.thisComponent.props.oRights.isPosting)
      return

    this.parentProps.actions.postRequest()
    this.setState({ isPosting: true })
    let clickedPermission = this.props.colDef.field
    let level = this.props.node.level
    let role = {
      target_id: this.props.data.resourceColumn[level],
      target_type: this.parentProps.oRights.targetTypes[level],
      roleid: this.props.data.roleid,
      r: (this.props.data.r || false) * 1,
      u: (this.props.data.u || false) * 1,
      c: (this.props.data.c || false) * 1,
      d: (this.props.data.d || false) * 1,
    }
    role[clickedPermission] = role[clickedPermission] ^ 1 // Negate with XOR
    postChanges( [role], this.parentProps.oRights.occupationId, this.parentProps.actions )
  }

  componentDidUpdate(prevProps) {
    // Remove spinner
    if (prevProps.context.thisComponent.props.oRights.isPosting !== this.props.context.thisComponent.props.oRights.isPosting) {
      this.setState({ isPosting: this.props.context.thisComponent.props.oRights.isPosting });
    }
  }

  render() {
    var unlocked = this.props.node.level>2 || !this.parentProps.oRights.levelLock
    return (
      <span className={this.state.permissionClass} style={{'fontSize': '.9rem', cursor: 'pointer'}} {...(unlocked && { onClick: () => this.togglePermission() })} >
        <div>
          { (!unlocked) &&
            <div className="oar-Lock">
              <Icons icon="lock" classes="svg-inline--fa fa-w-20"/>
            </div>
          }
          <span style={{opacity: unlocked ? '1' : '.5'}}>
            <Icons icon={this.state.icon} classes="svg-inline--fa fa-w-20"/>
          </span>
          { (this.state.isPosting) &&
            <div className="oar-Modal oar-PostSpinner"><div className="lds-dual-ring active"></div></div>
          }
          { this.props.data['child_'+this.props.colDef.field] &&
              <span className="oar-permitted" style={{position: 'absolute'}}>•</span>
          }
        </div>
      </span>
    );
  }
}