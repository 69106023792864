// Key is coming from gate's erp parameter
exports.list = {
  'PMen': 'pmen', 
  'Rapid': 'rapid',
  'Rapid-VH': 'rapid_vh',
  'Axapta': 'axapta',
  'BC': 'bc',
  'Merit': 'merit',
  'Standard Books': 'standardBooks',
  'SAF': 'saf',
  'Directo': 'directo',
}

exports.default= 'pmen'

const defaults = {
  original_prefix: 'erp_',
  fields: {
    id_kanne: {
      drop: true
    },
    summa: {
      type: 'currency'
    },
    osakond: {
      report_key: 'units',
      model_key: 'unit'
    },
    tegevusala: {
      report_key: 'functions',
      model_key: 'function'
    },
    kuluobjekt: {
      report_key: 'objects',
      model_key: 'item'
    },
    projekt: {
      report_key: 'projects',
      model_key: 'project'
    },

  }
}

exports.merit =  {
  ...defaults,
  title:  'Merit',
  
  fields: {
    ...defaults.fields,
    erp_BatchDate: {
      ...defaults.fields.erp_BatchDate,
      type: 'date',
      date_format: 'YYYY-MM-DD',
      month_field_id: 'erp_BatchDate_kuu',
      month_field_title: '*kuu (BatchDate)',
      month_field_format: 'YYYY-MM'
    },
    erp_BatchId: { drop: true },
    erp_EntryId: { drop: true },
    erp_GLBId: { drop: true },
    erp_CreditAmount: { ...defaults.fields.erp_CreditAmount, type: 'currency' },
    erp_CreditCurrency: { ...defaults.fields.erp_CreditCurrency, type: 'currency' },
    erp_CurrencyRate: { ...defaults.fields.erp_CurrencyRate, type: 'currency' },
    erp_DebitAmount: { ...defaults.fields.erp_DebitAmount, type: 'currency' },
    erp_DebitCurrency: { ...defaults.fields.erp_DebitCurrency, type: 'currency' },
    erp_PriceInclVat: { ...defaults.fields.erp_PriceInclVat, type: 'currency' },
    erp_TotalAmount: { ...defaults.fields.erp_TotalAmount, type: 'currency' },
  },
  
  views: {
    full: {
      title: 'Täisvaade',
      fn: (that) => {
      }
    },
    short: {
      title: 'Lühiinfo',
      fn: (that) => {
        let displayedColumnIds = ['erp_BatchDate_kuu','erp_BatchDate','erp_Document','erp_Memo','summa']
        let allColumnIds = that.gridColumnApi.getAllColumns().map( c => c.colId ) 
        that.gridColumnApi.setColumnsVisible(allColumnIds, false)
        that.gridColumnApi.setColumnsVisible(displayedColumnIds, true)
        that.gridColumnApi.moveColumns(displayedColumnIds, 0)
        that.gridColumnApi.autoSizeColumns(displayedColumnIds, false);
      }
    },
    month: {
      title: 'Summa kuu kaupa',
      fn: (that) => { // Summa kuu kaupa
        that.gridColumnApi.setRowGroupColumns(['erp_BatchDate_kuu'])
        that.gridColumnApi.addValueColumn('summa')
        that.gridColumnApi.moveColumn('summa', 1)
        that.gridApi.setSortModel([{colId: 'ag-Grid-AutoColumn', sort: 'asc'}])
      }
    }

  }
  
} // merit

exports.pmen =  {
  ...defaults,
  title:  'PMen',
  
  fields: {
    ...defaults.fields,
    erp_kuup: {
      ...defaults.fields.erp_kuup,
      type: 'date',
      date_format: 'YYYY-MM-DD',
      month_field_id: 'erp_kuup_kuu',
      month_field_title: '*kuu (kuup)',
      month_field_format: 'YYYY-MM'
    },
  },
  
  views: {
    full: {
      title: 'Täisvaade',
      fn: (that) => {
      }
    },
    short: {
      title: 'Lühiinfo',
      fn: (that) => {
        let displayedColumnIds = ['erp_kuup_kuu','erp_kuup','erp_doknr','erp_asulist_nimi','erp_info','summa']
        let allColumnIds = that.gridColumnApi.getAllColumns().map( c => c.colId ) 
        that.gridColumnApi.setColumnsVisible(allColumnIds, false)
        that.gridColumnApi.setColumnsVisible(displayedColumnIds, true)
        that.gridColumnApi.moveColumns(displayedColumnIds, 0)
        that.gridColumnApi.autoSizeColumns(displayedColumnIds, false);
      }
    },
    month: {
      title: 'Summa kuu kaupa',
      fn: (that) => { // Summa kuu kaupa
        that.gridColumnApi.setRowGroupColumns(['erp_kuup_kuu'])
        that.gridColumnApi.addValueColumn('summa')
        that.gridColumnApi.moveColumn('summa', 1)
        that.gridApi.setSortModel([{colId: 'ag-Grid-AutoColumn', sort: 'asc'}])
      }
    },
    asulist_month: {
      title: 'Isikute summa kuu kaupa',
      fn: (that) => { // Pivot
        that.gridColumnApi.setPivotMode(true)
        that.gridColumnApi.setPivotColumns(['erp_kuup_kuu'])
        that.gridColumnApi.setRowGroupColumns(['erp_asulist_nimi'])
        that.gridColumnApi.addValueColumn('summa')
        // Give a tick to group columns
        setTimeout(()=>{
          that.autoSizeAllColumns()
        },0)
      }
    }

  }
  
} // pmen

// Viljandi Haigla
exports.rapid_vh =  {
  ...defaults,
  title:  'Rapid-VH',

  fields: {
    ...defaults.fields,
    erp_RmpKuup: {
      ...defaults.fields.erp_RmpKuup,
      type: 'date',
      date_format: 'DD.MM.YYYY',
      month_field_id: 'erp_RmpKuup_kuu',
      month_field_title: '*kuu (RmpKuup)',
      month_field_format: 'YYYY-MM'
    },
  },

  views: {
    full: {
      title: 'Täisvaade',
      fn: (that) => {
      }
    },
    short: {
      title: 'Lühiinfo',
      fn: (that) => {
        let displayedColumnIds = ['erp_RmpKuup_kuu','erp_RmpKuup','erp_DokNr','erp_HankArve','erp_KliNimi','erp_Selgitus','summa']
        let allColumnIds = that.gridColumnApi.getAllColumns().map( c => c.colId )
        that.gridColumnApi.setColumnsVisible(allColumnIds, false)
        that.gridColumnApi.setColumnsVisible(displayedColumnIds, true)
        that.gridColumnApi.moveColumns(displayedColumnIds, 0)
        that.gridColumnApi.autoSizeColumns(displayedColumnIds, false);
      }
    },
    month: {
      title: 'Summa kuu kaupa',
      fn: (that) => { // Summa kuu kaupa
        that.gridColumnApi.setRowGroupColumns(['erp_RmpKuup_kuu'])
        that.gridColumnApi.addValueColumn('summa')
        that.gridColumnApi.moveColumn('summa', 1)
        that.gridApi.setSortModel([{colId: 'ag-Grid-AutoColumn', sort: 'asc'}])
      }
    },
    asulist_month: {
      title: 'Isikute summa kuu kaupa',
      fn: (that) => { // Pivot
        that.gridColumnApi.setPivotMode(true)
        that.gridColumnApi.setPivotColumns(['erp_RmpKuup_kuu'])
        that.gridColumnApi.setRowGroupColumns(['erp_KliNimi'])
        that.gridColumnApi.addValueColumn('summa')
        // Give a tick to group columns
        setTimeout(()=>{
          that.autoSizeAllColumns()
        },0)
      }
    }

  }
} // rapid Viljandi Haigla

exports.rapid =  {
  ...defaults,
  title:  'Rapid',
  
  fields: {
    ...defaults.fields,
    erp_RmpKuup: {
      ...defaults.fields.erp_RmpKuup,
      type: 'date',
      date_format: 'DD.MM.YYYY',
      month_field_id: 'erp_RmpKuup_kuu',
      month_field_title: '*kuu (RmpKuup)',
      month_field_format: 'YYYY-MM'
    },
  },
  
  views: {
    full: {
      title: 'Täisvaade',
      fn: (that) => {
      }
    },
    short: {
      title: 'Lühiinfo',
      fn: (that) => {
        let displayedColumnIds = ['erp_RmpKuup_kuu','erp_RmpKuup','erp_DokNr','erp_HankArve','erp_KliNimi','summa']
        let allColumnIds = that.gridColumnApi.getAllColumns().map( c => c.colId ) 
        that.gridColumnApi.setColumnsVisible(allColumnIds, false)
        that.gridColumnApi.setColumnsVisible(displayedColumnIds, true)
        that.gridColumnApi.moveColumns(displayedColumnIds, 0)
        that.gridColumnApi.autoSizeColumns(displayedColumnIds, false);
      }
    },
    month: {
      title: 'Summa kuu kaupa',
      fn: (that) => { // Summa kuu kaupa
        that.gridColumnApi.setRowGroupColumns(['erp_RmpKuup_kuu'])
        that.gridColumnApi.addValueColumn('summa')
        that.gridColumnApi.moveColumn('summa', 1)
        that.gridApi.setSortModel([{colId: 'ag-Grid-AutoColumn', sort: 'asc'}])
      }
    },
    asulist_month: {
      title: 'Isikute summa kuu kaupa',
      fn: (that) => { // Pivot
        that.gridColumnApi.setPivotMode(true)
        that.gridColumnApi.setPivotColumns(['erp_RmpKuup_kuu'])
        that.gridColumnApi.setRowGroupColumns(['erp_KliNimi'])
        that.gridColumnApi.addValueColumn('summa')
        // Give a tick to group columns
        setTimeout(()=>{
          that.autoSizeAllColumns()
        },0)
      }
    }

  }
  
} // rapid

exports.axapta =  {
  ...defaults,
  title:  'Axapta',
  
  fields: {
    ...defaults.fields,
    erp_kuupaev: {
      ...defaults.fields.erp_kuupaev,
      type: 'date',
      date_format: 'YYYY-MM-DD',
      month_field_id: 'erp_kuupaev_kuu',
      month_field_title: '*kuu (kuupaev)',
      month_field_format: 'YYYY-MM'
    },
  },
  
  views: {
    full: {
      title: 'Täisvaade',
      fn: (that) => {
      }
    },
    short: {
      title: 'Lühiinfo',
      fn: (that) => {
        let displayedColumnIds = ['erp_kuupaev_kuu','erp_kuupaev','erp_tp_kood','erp_dok_nr','erp_partneri_nimetus','erp_info','summa']
        let allColumnIds = that.gridColumnApi.getAllColumns().map( c => c.colId )
        that.gridColumnApi.setColumnsVisible(allColumnIds, false)
        that.gridColumnApi.setColumnsVisible(displayedColumnIds, true)
        that.gridColumnApi.moveColumns(displayedColumnIds, 0)
        that.gridColumnApi.autoSizeColumns(displayedColumnIds, false);
      }
    },
    month: {
      title: 'Summa kuu kaupa',
      fn: (that) => { // Summa kuu kaupa
        that.gridColumnApi.setRowGroupColumns(['erp_kuupaev_kuu'])
        that.gridColumnApi.addValueColumn('summa')
        that.gridColumnApi.moveColumn('summa', 1)
        that.gridApi.setSortModel([{colId: 'ag-Grid-AutoColumn', sort: 'asc'}])
      }
    },
    asulist_month: {
      title: 'Isikute summa kuu kaupa',
      fn: (that) => { // Pivot
        that.gridColumnApi.setPivotMode(true)
        that.gridColumnApi.setPivotColumns(['erp_kuupaev_kuu'])
        that.gridColumnApi.setRowGroupColumns(['erp_partneri_nimetus'])
        that.gridColumnApi.addValueColumn('summa')
        // Give a tick to group columns
        setTimeout(()=>{
          that.autoSizeAllColumns()
        },0)
      }
    }

  }
  
} // axapta

exports.bc =  {
  ...defaults,
  title:  'BC',

  fields: {
    ...defaults.fields,
    erp_postingDate: {
      ...defaults.fields.erp_postingDate,
      type: 'date',
      date_format: 'YYYY-MM-DD',
      month_field_id: 'erp_kuu',
      month_field_title: '*kuu (postingDate)',
      month_field_format: 'YYYY-MM'
    },
    erp_vatAmount: {
      ...defaults.fields.erp_vatAmount,
      type: 'currency'
    },
    erp_auxiliaryIndex1: { drop: true },
    erp_auxiliaryIndex2: { drop: true },
    erp_auxiliaryIndex3: { drop: true },
    erp_auxiliaryIndex4: { drop: true },
    erp_auxiliaryIndex5: { drop: true },
    erp_auxiliaryIndex6: { drop: true },
    erp_auxiliaryIndex7: { drop: true },
    erp_auxiliaryIndex8: { drop: true },
    erp_auxiliaryIndex9: { drop: true },
    erp_auxiliaryIndex10: { drop: true },
    erp_auxiliaryIndex11: { drop: true },
    erp_auxiliaryIndex12: { drop: true },
    erp_auxiliaryIndex13: { drop: true },
    erp_auxiliaryIndex14: { drop: true },
    erp_auxiliaryIndex15: { drop: true },
    erp_auxiliaryIndex16: { drop: true },
    erp_auxiliaryIndex17: { drop: true },
    erp_auxiliaryIndex18: { drop: true },
    erp_auxiliaryIndex19: { drop: true },
    erp_auxiliaryIndex20: { drop: true },
    erp_auxiliaryIndex21: { drop: true },
    erp_auxiliaryIndex22: { drop: true },
  },

  views: {
    full: {
      title: 'Täisvaade',
      fn: (that) => {
      }
    },
    short: {
      title: 'Lühiinfo',
      fn: (that) => {
        let displayedColumnIds = ['erp_kuu','erp_postingDate','erp_dimTkl','erp_accountNo','tegevusala','osakond','erp_externalDocumentNo','erp_sourceName','summa']
        let allColumnIds = that.gridColumnApi.getAllColumns().map( c => c.colId )
        that.gridColumnApi.setColumnsVisible(allColumnIds, false)
        that.gridColumnApi.setColumnsVisible(displayedColumnIds, true)
        that.gridColumnApi.moveColumns(displayedColumnIds, 0)
        that.gridColumnApi.autoSizeColumns(displayedColumnIds, false);
      }
    },
    month: {
      title: 'Summa kuu kaupa',
      fn: (that) => { // Summa kuu kaupa
        that.gridColumnApi.setRowGroupColumns(['erp_kuu'])
        that.gridColumnApi.addValueColumn('summa')
        that.gridColumnApi.moveColumn('summa', 1)
        that.gridApi.setSortModel([{colId: 'ag-Grid-AutoColumn', sort: 'asc'}])
      }
    },
    asulist_month: {
      title: 'Isikute summa kuu kaupa',
      fn: (that) => { // Pivot
        that.gridColumnApi.setPivotMode(true)
        that.gridColumnApi.setPivotColumns(['erp_kuu'])
        that.gridColumnApi.setRowGroupColumns(['erp_sourceName'])
        that.gridColumnApi.addValueColumn('summa')
        // Give a tick to group columns
        setTimeout(()=>{
          that.autoSizeAllColumns()
        },0)
      }
    }

  }

} // bc

exports.standardBooks =  {
  ...defaults,
  title:  'Standard Books',
  
  fields: {
    ...defaults.fields,
    erp_transdate: {
      ...defaults.fields.erp_transdate,
      type: 'date',
      date_format: 'YYYY-MM-DD',
      month_field_id: 'erp_kuu',
      month_field_title: '*kuu (transdate)',
      month_field_format: 'YYYY-MM'
    },
  },
  
  views: {
    full: {
      title: 'Täisvaade',
      fn: (that) => {
      }
    },
    short: {
      title: 'Lühiinfo',
      fn: (that) => {
        let displayedColumnIds = ['erp_transdate','erp_comment','summa']
        let allColumnIds = that.gridColumnApi.getAllColumns().map( c => c.colId )
        that.gridColumnApi.setColumnsVisible(allColumnIds, false)
        that.gridColumnApi.setColumnsVisible(displayedColumnIds, true)
        that.gridColumnApi.moveColumns(displayedColumnIds, 0)
        that.gridColumnApi.autoSizeColumns(displayedColumnIds, false);
      }
    },
    month: {
      title: 'Summa kuu kaupa',
      fn: (that) => { // Summa kuu kaupa
        that.gridColumnApi.setRowGroupColumns(['erp_kuu'])
        that.gridColumnApi.addValueColumn('summa')
        that.gridColumnApi.moveColumn('summa', 1)
        that.gridApi.setSortModel([{colId: 'ag-Grid-AutoColumn', sort: 'asc'}])
      }
    },
    asulist_month: {
      title: 'Sisu kommentaarist kuu kaupa',
      fn: (that) => { // Pivot
        that.gridColumnApi.setPivotMode(true)
        that.gridColumnApi.setPivotColumns(['erp_kuu'])
        that.gridColumnApi.setRowGroupColumns(['erp_comment'])
        that.gridColumnApi.addValueColumn('summa')
        // Give a tick to group columns
        setTimeout(()=>{
          that.autoSizeAllColumns()
        },0)
      }
    }

  }
  
} // standardBooks

exports.saf =  {
  ...defaults,
  title:  'SAF',
  
  fields: {
    ...defaults.fields,
    erp_Dokumendi_Kuupaev: {
      ...defaults.fields.erp_Dokumendi_Kuupaev,
      type: 'date',
      date_format: 'YYYY-MM-DD',
      month_field_id: 'erp_Dokumendi_Kuu',
      month_field_title: '*kuu',
      month_field_format: 'YYYY-MM'
    },
    erp_Summa_baasvaluutas: { ...defaults.fields.erp_Summa_baasvaluutas, type: 'currency' },
    erp_Summa_dokumendi_valuutas: { ...defaults.fields.erp_Summa_dokumendi_valuutas, type: 'currency' },
    erp_Kogus: { ...defaults.fields.erp_Summa_dokumendi_valuutas, type: 'numeric' },
  },
  
  views: {
    full: {
      title: 'Täisvaade',
      fn: (that) => {
      }
    },
    short: {
      title: 'Lühiinfo',
      fn: (that) => {
        let displayedColumnIds = ['erp_Dokumendi_Kuupaev','erp_KR_Firma_nimi','erp_Dokumendi_selgitus','summa']
        let allColumnIds = that.gridColumnApi.getAllColumns().map( c => c.colId ) 
        that.gridColumnApi.setColumnsVisible(allColumnIds, false)
        that.gridColumnApi.setColumnsVisible(displayedColumnIds, true)
        that.gridColumnApi.moveColumns(displayedColumnIds, 0)
        that.gridColumnApi.autoSizeColumns(displayedColumnIds, false);
      }
    },
    month: {
      title: 'Summa kuu kaupa',
      fn: (that) => { // Summa kuu kaupa
        that.gridColumnApi.setRowGroupColumns(['erp_Dokumendi_Kuu'])
        that.gridColumnApi.addValueColumn('summa')
        that.gridColumnApi.moveColumn('summa', 1)
        that.gridApi.setSortModel([{colId: 'ag-Grid-AutoColumn', sort: 'asc'}])
      }
    }

  }
  
} // saf

exports.directo =  {
  ...defaults,
  title:  'Directo',

  fields: {
    ...defaults.fields,
    erp_date: {
      ...defaults.fields.erp_date,
      type: 'date',
      date_format: 'YYYY-MM-DD',
      month_field_id: 'erp_kuu',
      month_field_title: '*kuu',
      month_field_format: 'YYYY-MM'
    },
    erp_creditamount: { ...defaults.fields.erp_creditamount, type: 'currency' },
    erp_debitamount: { ...defaults.fields.erp_debitamount, type: 'currency' },
    erp_sum: { ...defaults.fields.erp_sum, type: 'currency' },
    erp_currate: { ...defaults.fields.erp_currate, type: 'numeric' },
  },

  views: {
    full: {
      title: 'Täisvaade',
      fn: (that) => {
      }
    },
    short: {
      title: 'Lühiinfo',
      fn: (that) => {
        let displayedColumnIds = ['erp_kuu','erp_date','erp_number','erp_custimer','erp_customer','erp_supplier','erp_comment','erp_description','summa']
        let allColumnIds = that.gridColumnApi.getAllColumns().map( c => c.colId )
        that.gridColumnApi.setColumnsVisible(allColumnIds, false)
        that.gridColumnApi.setColumnsVisible(displayedColumnIds, true)
        that.gridColumnApi.moveColumns(displayedColumnIds, 0)
        that.gridColumnApi.autoSizeColumns(displayedColumnIds, false);
      }
    },
    month: {
      title: 'Summa kuu kaupa',
      fn: (that) => { // Summa kuu kaupa
        that.gridColumnApi.setRowGroupColumns(['erp_kuu'])
        that.gridColumnApi.addValueColumn('summa')
        that.gridColumnApi.moveColumn('summa', 1)
        that.gridApi.setSortModel([{colId: 'ag-Grid-AutoColumn', sort: 'asc'}])
      }
    }

  }
} // directo
