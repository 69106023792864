import PropTypes from 'prop-types';
import React from 'react';

import '../../Core/assets/styles/main.scss';
import '../assets/styles/styles.scss';

import erpConfig from '../../Core/constants/erpConfig'
import { t } from '../../Core/locale/locale.jsx'

import Icons from '../../Core/assets/icons/fa-custom.jsx';
import '../../Core/assets/styles/fa-custom.scss';

function ViewSelect(props) {
  return  <select onChange={props.onChange} value={props.value}>
            {Object.keys(erpConfig[props.erpId].views).map( v => {
              return <option value={v} key={v}>{erpConfig[props.erpId].views[v].title}</option>
            })}
          </select>
}

export default class Toolbar extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    erp: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      quickFilterText: ''
    }
  }

  changeView = (event) => {
    this.props.actions.changeView(event.target.value);
  }
  
  exportToExcel(mode) {
    let params = {
      sheetName: 'veera',
      exportMode: mode
//      processCellCallback: params => {
//        return params.value
//      }
    }
    this.props.erp.gridApi.exportDataAsExcel(params)
  }

  exportToCsv() {
    let params = {
    }
    this.props.erp.gridApi.exportDataAsCsv(params)
  }

  copyTableToClipboard() {
    this.props.erp.gridApi.selectAll()
    this.props.erp.gridApi.copySelectedRowsToClipboard(true)    
    setTimeout( () => this.props.erp.gridApi.deselectAll(), 300) // Blink
  }

  hideEmptyColumns() {
    let colIds = this.props.erp.gridColumnApi.getAllGridColumns().filter(col => !(col.userProvidedColDef || {}).hasData).map( col => col.colId )
    this.props.erp.gridColumnApi.setColumnsVisible(colIds, false)
  }

  autoSizeColumns = () => {
    let allColumnIds = this.props.erp.gridColumnApi.getAllGridColumns().map( c => c.colId ) 
    this.props.erp.gridColumnApi.autoSizeColumns(allColumnIds, false);
  }

  removeFilters = () => {
    this.filterTextBoxReset()
    this.props.erp.gridApi.setFilterModel(null);
    this.props.erp.gridApi.onFilterChanged();
  }
  
  toggleTextForCodes = () => {
    let newCodeExpanded = (this.props.erp.codeExpanded + 1 ) % 3
    this.props.actions.setGlobalCodeExpanded( newCodeExpanded )
  }
  
  onFilterTextBoxChanged() {
    this.setState({quickFilterText: document.getElementById('erp-filter-text-box').value})
    this.props.erp.gridApi.setQuickFilter(document.getElementById('erp-filter-text-box').value);
  }
  filterTextBoxReset() {
    document.getElementById('erp-filter-text-box').value="";
    this.onFilterTextBoxChanged()
  }
  onFilterTextBoxKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.filterTextBoxReset()
    }
  }
  
  
  
  render() {
    return (
      <div className="erp-Toolbar">
        <div className="erp-ToolbarItem">
          <div className="erp-ToolbarInput">
            <input type="test" autoComplete="off" id="erp-filter-text-box" placeholder={t('erp.search')} 
              className={(this.state.quickFilterText!=='') ? 'erp-quickFilterPresent' : ''}
              onChange={() => this.onFilterTextBoxChanged()} 
              onKeyDown={this.onFilterTextBoxKeyDown} />
            <span className="erp-inputResetButton" onClick={() => this.filterTextBoxReset()}>
              <Icons icon="times" classes="svg-inline--fa fa-w-18"/>
            </span>
          </div>
        </div>
        <div className="erp-ToolbarItem">
          <div className="erp-ToolbarSelect">
            <label>{t('erp.toolbar.view')}:</label>
            <ViewSelect onChange={this.changeView} value={this.props.erp.viewId} erpId={this.props.erp.erpId} />
          </div>
        </div>
        <div className="erp-ToolbarItem">
          <label>{t('erp.toolbar.manageView')}:</label>
          <button className="erp-ToolbarButton" onClick={() => this.hideEmptyColumns()} title={t('erp.buttonTooltip.hideEmpty')}>
            <Icons icon="compress-alt" classes="svg-inline--fa fa-w-18"/>
          </button>
          <button className="erp-ToolbarButton" onClick={() => this.autoSizeColumns()} title={t('erp.buttonTooltip.columnWidth')}>
            <Icons icon="columns" classes="svg-inline--fa fa-w-18"/>
          </button>
          <button className={`erp-ToolbarButton ${this.props.erp.codeExpanded>0 && 'border-gray-999'}`} onClick={() => this.toggleTextForCodes()} title={t('erp.buttonTooltip.switchCodeName')}>
            <span className={`${this.props.erp.codeExpanded===1 ? 'gray-ccc' : 'bold'}`}>1</span>
            <span className="gray-999">-</span>
            <span className={`${this.props.erp.codeExpanded===0 ? 'gray-ccc' : 'bold'}`}>A</span>
          </button>
          <button className={`erp-ToolbarButton ${(this.props.erp.isAnyFilterPresent) ? 'border-red-faa' : 'gray-999'}`} onClick={() => this.removeFilters()} disabled={!this.props.erp.isAnyFilterPresent} title={t('erp.buttonTooltip.removeFilters')}>
            <Icons icon="filter-remove" classes="svg-inline--fa fa-w-18"/>
          </button>
        </div>
        <div className="erp-ToolbarItem">
          <label>{t('erp.toolbar.export')}:</label>
          <button className="erp-ToolbarButton" onClick={() => this.exportToExcel()} style={{color: 'green'}} title={t('erp.buttonTooltip.excel')}>
            <Icons icon="file-excel" classes="svg-inline--fa fa-w-18"/> Excel
          </button>
          <button className="erp-ToolbarButton" onClick={() => this.exportToExcel('xml')} title={t('erp.buttonTooltip.xml')}>
            <Icons icon="file-excel" classes="svg-inline--fa fa-w-18"/> XML
          </button>
          <button className="erp-ToolbarButton" onClick={() => this.exportToCsv()} title={t('erp.buttonTooltip.csv')}>
            <Icons icon="file-csv" classes="svg-inline--fa fa-w-18"/> CSV
          </button>
          <button className="erp-ToolbarButton" onClick={() => this.copyTableToClipboard()} title={t('erp.buttonTooltip.copy')}>
            <Icons icon="copy" classes="svg-inline--fa fa-w-18"/> {t('erp.buttonTooltip.copy')}
          </button>
        </div>
      </div>
    );
  }
}
