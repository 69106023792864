import PropTypes from 'prop-types';
import React from 'react';

import { t } from '../../Core/locale/locale.jsx'
import Icons from '../../Core/assets/icons/fa-custom.jsx';
import '../../Core/assets/styles/fa-custom.scss';

export default class Toolbar extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    oRights: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      quickFilterText: ''
    }
  }
/*
  removeFilters = () => {
    this.filterTextBoxReset()
    this.props.oRights.gridApi.setFilterModel(null);
    this.props.oRights.gridApi.onFilterChanged();
  }
*/  
  onFilterTextBoxChanged() {
    this.setState({quickFilterText: document.getElementById('oar-filter-text-box').value})
    this.props.oRights.gridApi.setQuickFilter(document.getElementById('oar-filter-text-box').value);
  }
  filterTextBoxReset() {
    document.getElementById('oar-filter-text-box').value="";
    this.onFilterTextBoxChanged()
  }
  onFilterTextBoxKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.filterTextBoxReset()
    }
  }

  onLevelLockChanged() {
    this.props.actions.toggleLevelLock()
    this.props.oRights.gridApi.redrawRows()
  }

  render() {
    return (
      <div className="oar-Toolbar" style={{justifyContent: 'space-between'}}>
        <div className="oar-ToolbarItem">
          <div className="oar-ToolbarInput">
            <input type="test" autoComplete="off" id="oar-filter-text-box" placeholder={t('oar.search')} 
              className={(this.state.quickFilterText!=='') ? 'oar-quickFilterPresent' : ''}
              onChange={() => this.onFilterTextBoxChanged()} 
              onKeyDown={this.onFilterTextBoxKeyDown} />
            <span className="oar-inputResetButton" onClick={() => this.filterTextBoxReset()}>
              <Icons icon="times" classes="svg-inline--fa fa-w-18"/>
            </span>
          </div>
        </div>
        <div className="oar-ToolbarItem">
          <div className="oar-ToolbarInput">
            <label style={{fontSize: '1em'}}>
              <input type="checkbox" style={{verticalAlign: 'middle', marginRight: '.5rem'}}
                checked={this.props.oRights.levelLock}
                onChange={() => this.onLevelLockChanged()} 
              />
              {t('oar.allow_sb_rights_only')}
            </label>
          </div>
        </div>
      </div>
    );
  }
}
