// ERP Records
import React from 'react';
import {Provider} from 'react-redux';
import ReactOnRails from 'react-on-rails';
//import { AppContainer } from 'react-hot-loader';
import ReactDOM from 'react-dom';

import MainContainer from '../containers/MainContainer.jsx';

function hideErpComponent() {
  $("div[id^='ErpRecords-react-component']").parent().removeClass('active')
  $('.link-to-detail-row-active').removeClass('link-to-detail-row-active')
  $('.link-to-detail-cell-active').removeClass('link-to-detail-cell-active')
}

export default (props, _railsContext, domNodeId) => {
  const render = props.prerender ? ReactDOM.hydrate : ReactDOM.render;
  // eslint-disable-next-line no-param-reassign
  delete props.prerender;

  hideErpComponent()

  const store = ReactOnRails.getStore('SharedStore');

  const element = (
      <Provider store={store}>
        <MainContainer />
      </Provider>
  );
  render(element, document.getElementById(domNodeId));
};
