/* global $ */

import PropTypes from 'prop-types';
import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from "@ag-grid-enterprise/all-modules";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

//import helpers from '../helpers/helpers';
import PermissionRenderer from './PermissionRenderer.jsx';
import RowMenuRenderer from './RowMenuRenderer.jsx';
import PermissionHeader from './PermissionHeader.jsx';

import { t } from '../../Core/locale/locale.jsx'
import localeText from '../../Core/constants/aggridLocale'
import Icons from '../../Core/assets/icons/fa-custom.jsx';
import '../../Core/assets/styles/fa-custom.scss';
import '../assets/styles/styles.scss';

function subBudgetRightsFilter(params) {
  // Organization and document always, budgets only with sub-budgets, sub-budgets with right
  return (typeof params.data==='undefined')
    ? false
    : ((params.data.resourceColumn || []).length<3)
      ? true
      : ((params.data.resourceColumn || []).length===3)
        ? false
        : (typeof params.data[params.colDef.field]==='undefined') 
          ? false
          : params.data[params.colDef.field]

}


export default class Table extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    oRights: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    let rightsColumnTemplate = { 
      minWidth: 30, 
      headerClass: 'oar-permissionHeader', 
      cellClass: 'oar-middle', 
      filterValueGetter: subBudgetRightsFilter, 
      cellRenderer: 'permissionCellRenderer', 
      headerComponent: 'permissionHeaderComponent'
    }
    this.state = {
      gridOptions: {
        modules: AllModules,
        localeText: localeText[this.props.oRights.locale],
        columnDefs: [
          { ...rightsColumnTemplate, field: 'r', colId: 'r', headerName: t('oar.read') },
          { ...rightsColumnTemplate, field: 'u', colId: 'u', headerName: t('oar.update'), minWidth: 30 }, 
          { ...rightsColumnTemplate, field: 'c', colId: 'c', headerName: t('oar.create'), minWidth: 30 }, 
          { ...rightsColumnTemplate, field: 'd', colId: 'd', headerName: t('oar.delete'), minWidth: 30 },

          { field: 'inherited_r', hide: true },
          { field: 'inherited_u', hide: true },
          { field: 'inherited_c', hide: true },
          { field: 'inherited_d', hide: true },
          
          { field: 'child_r', hide: true },
          { field: 'child_u', hide: true },
          { field: 'child_c', hide: true },
          { field: 'child_d', hide: true },

          { field: 'orgid',   hide: true, getQuickFilterText: () => '' },
          { field: 'docid',   hide: true, getQuickFilterText: () => '' },
          { field: 'budid',   hide: true, getQuickFilterText: () => '' },
          { field: 'sbudid',  hide: true, getQuickFilterText: () => '' },
          { field: 'resource_title', hide: true },

          { field: 'unit_code', hide: true },
          { field: 'function_code', hide: true },
          { field: 'object_code', hide: true },

          { field: 'row_menu', headerName: "", cellRenderer: 'rowMenuRenderer', cellClass: 'oar-RowMenuCell', width: 28 },

          { field: 'helper', headerName: "", width: 20, minWidth: 20, maxWidth: 20 },
        ],
        autoGroupColumnDef: {
          headerName: '',
          suppressMenu: true,
          minWidth: 320,
          cellRendererParams: { 
            suppressCount: true,
            suppressPadding: false,
            innerRenderer: 'resourceCellRenderer',
          },
          getQuickFilterText: () => '',
          cellClass: ['agchange-smaller-indent'],
          autoHeight: true,
        },
        defaultColDef: {
          enableValue: false,
          enableRowGroup: false,
          enablePivot: false,
          sortable: false,
          filter: true,
          resizable: false,
          suppressMenu: true,
          width: 60
        },
        groupDefaultExpanded: 2,
        getDataPath: function(data) {
          return data.resourceColumn
        },
        rowClassRules: {
          'oar-budget-row': function(params) {
            return params.node.level === 2
          },
/*          'oar-subbudget-row': function(params) {
            return params.node.level === 3
          },*/
        },
        components: { resourceCellRenderer: getResourceCellRenderer() },
        frameworkComponents: { 
          permissionCellRenderer: PermissionRenderer, 
          rowMenuRenderer: RowMenuRenderer, 
          permissionHeaderComponent: PermissionHeader,
        },
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left"
            }
          ]
        }
      },
    }
  }

  componentDidMount() {
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.gridApi) {
      if (this.props.oRights.isLoading) {
        this.gridApi.showLoadingOverlay()
      } else {
        this.gridApi.hideOverlay();
      }
    }
  }

  onGridSizeChanged = params => {
    this.sizeColumns( params )
  }

  onFirstDataRendered = params => {
    // Fix Edge not generating grid size change event on first loading
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      this.sizeColumns( params )
    }
  }
  
  sizeColumns( params ) {
    if ($('.ag-root-wrapper').first().width()>0) {
      // Use fixed-width helper column to make room for scrollbar
      // Permanent scrollbar is ugly and ag-grid's alwaysShowVerticalScroll does not seem to work
      params.columnApi.setColumnVisible('helper', true)
      params.api.sizeColumnsToFit()
      params.columnApi.setColumnVisible('helper', false)
    }
  }

  getRowNodeId( data ) {
    return data.resourceColumn.join('-')
  }
  
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.props.actions.exposeGridApis( this.gridApi, this.gridColumnApi, this.state.gridOptions )
  };
  
  render() {
    return (
      <div
        className="ag-theme-balham"
        style={{
          height: 'calc(100% - 70px)',
          width: 'calc(100% - 6px)' }}
      >
        <AgGridReact
          rowData={this.props.oRights.rowData}
          modules={this.state.gridOptions.modules}
          columnDefs={this.state.gridOptions.columnDefs}
          defaultColDef={this.state.gridOptions.defaultColDef}
          getRowNodeId={this.getRowNodeId}

          rowClassRules={this.state.gridOptions.rowClassRules}
          localeText={this.state.gridOptions.localeText}
          autoGroupColumnDef={this.state.gridOptions.autoGroupColumnDef}
          getDataPath={this.state.gridOptions.getDataPath}
          groupDefaultExpanded={this.state.gridOptions.groupDefaultExpanded}
          treeData={true}
          excludeChildrenWhenTreeDataFiltering = {true}
          components={this.state.gridOptions.components}
          frameworkComponents={this.state.gridOptions.frameworkComponents}

          suppressCellSelection={true}
          suppressContextMenu={true}
          // suppressRowTransform needed for row menu to work
          suppressRowTransform={true}
          animateRows={true}
          sideBar={false}
          statusBar={this.state.gridOptions.statusBar}

          onGridReady={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered}
          onGridSizeChanged={this.onGridSizeChanged}

          context={{ thisComponent: this }}
        >
        </AgGridReact>
      </div>      
    );
  }
}

function getResourceCellRenderer() {
  function ResourceCellRenderer() {}
  ResourceCellRenderer.prototype.init = function(params) {
    var props = params.context.thisComponent.props
    var tempDiv = document.createElement('div');
    var title = params.data.resource_title

    switch (params.node.level) {
      case 3: // Subbudget
        let codes = []
        if (params.data['unit_code'])     codes.push('Üksus: '+params.data['unit_code'])
        if (params.data['function_code']) codes.push('Tegevusala: '+params.data['function_code'])
        if (params.data['object_code'])   codes.push('Objekt: '+params.data['object_code'])
        tempDiv.innerHTML = '<div class="oar-resourceTitle">' +
            title +
            '<br>' +
            '<span style="font-size: .7rem;" class="text-muted mono">'+
              codes.join(', ')+
            '</span>' +
          '</div>';

        break;

      default:
        tempDiv.innerHTML = title;
    }  

    this.eGui = tempDiv.firstChild;
  };
  ResourceCellRenderer.prototype.getGui = function() {
    return this.eGui;
  };
  return ResourceCellRenderer;
}
