import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Toolbar from '../components/Toolbar';

import * as MainActions from '../actions/MainActions';

const ToolbarContainer = ({ actions, oRights }) => (
  <Toolbar {...{ actions, oRights }} />
);

ToolbarContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  oRights: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    oRights: state.oRights,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({...MainActions}, dispatch) };
}

// Don't forget to actually use connect!
export default connect(mapStateToProps, mapDispatchToProps)(ToolbarContainer);
