import moment from 'moment'

import {  OAR_LOAD_SUCCESS,
          OAR_LOAD_FAILURE, 
          OAR_LOAD_REQUEST, 
          OAR_UPDATE, 
          OAR_UPDATE_ROLES_ONLY,
          OAR_SET_ROLE,
          OAR_REDRAW_INHERITANCE, 
          OAR_EXPOSE_GRID_APIS,
          OAR_POST_REQUEST, 
          OAR_POST_SUCCESS, 
          OAR_POST_FAILURE, 
          OAR_CLEAR_ERRORS, 
          OAR_SET_LOCALE, 
          OAR_SET_FILTER,
          OAR_TOGGLE_LEVEL_LOCK
        } from '../actions/MainActionTypes'

import { setLanguage } from '../../Core/locale/locale.jsx'

const initialState = {
  canManage: false,
  occupationId: null,
  documentId: null,
  loaded: false,
  response: {},
//  resourceMappings: [],
  params: {},
  rowData: [],
  targetTypes: ['Organization','BudgetDocument','Budget','SubBudget'],
  permissionTypes: ["r", "u", "c", "d"],
  filteredPermission: null,
  levelLock: true
};

export default function MainReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {

    case OAR_LOAD_REQUEST:
      return Object.assign( {}, state, {
        isLoading: true,
        params: action.params
      })

    case OAR_LOAD_SUCCESS:
      return Object.assign( {}, state, {
        isLoading: false,
        success: true,
        loaded: true,
      })

    case OAR_LOAD_FAILURE:
      console.log("Loading failure")
      console.error(action.error)
      return Object.assign( {}, state, {
        isLoading: false,
        success: false,
        error: action.error,
      })

    case OAR_UPDATE:
      const safeRoles = (role) => {
        return { r: (role || {}).r, u: (role || {}).u, c: (role || {}).c, d: (role || {}).d }
      }
      var res = action.response
      var rowData = []

      // Organization
      rowData.push( Object.assign( 
          {}, 
          { 
            resourceColumn: [res.organization.id],
            resource_title: res.organization.name,
            orgid: res.organization.id,
          },
          safeRoles(res.organization_role)
      ))

      // Budget document
      if (res.budget_document) {
        rowData.push( Object.assign(
            {},
            {
              resourceColumn: [res.organization.id, res.budget_document.id], 
              resource_title: res.budget_document.name,
              orgid: res.organization.id,
              docid: res.budget_document.id
            },
            safeRoles(res.budget_document_role)
        ))
      }
      // Budgets
      if (Array.isArray(res.budgets)) {
        res.budgets.forEach( budget => {
          rowData.push( Object.assign( 
              {}, 
              { 
                resourceColumn: [res.organization.id, res.budget_document.id, budget.id], 
                resource_title: budget.name,
                orgid: res.organization.id,
                docid: res.budget_document.id,
                budid: budget.id
              },
              safeRoles(res.budget_roles[budget.id])
          ))

          // Sub-budgets
          if (Array.isArray(res.sub_budgets)) {
            res.sub_budgets
              .filter( sb => sb.budget_id===budget.id )
              .forEach( bsb => {
                rowData.push( Object.assign( 
                    {}, 
                    { 
                      resourceColumn: [res.organization.id, res.budget_document.id, budget.id, bsb.id ], 
                      resource_title: bsb.name,
                      orgid: res.organization.id,
                      docid: res.budget_document.id,
                      budid: budget.id,
                      sbudid: bsb.id
                    },
                    safeRoles(res.sub_budget_roles[bsb.id]), 
                    { 
                      unit_code: bsb.unit, 
                      function_code: bsb.function, 
                      object_code: bsb.object 
                    } 
                ))
              })
          }
        })
      }
      
      return Object.assign( {}, state, {
        response: res,
        occupationId: action.params.occupation_id,
        documentId: (res.budget_document || {}).id,
        rowData: rowData,
      })

    case OAR_UPDATE_ROLES_ONLY:
      const assignRoles = ( target, source ) => {
        target.r=source.r
        target.u=source.u
        target.c=source.c
        target.d=source.d
      }
      
      var res = action.response
      var rowData = state.rowData

      rowData.forEach( row => {
        row.r=undefined
        row.u=undefined
        row.c=undefined
        row.d=undefined
      })
      
      assignRoles( rowData[0], res.organization_role )

      if (res.budget_document)
        assignRoles( rowData[1], res.budget_document_role )

      Object.keys(res.budget_roles || {}).forEach( bid => {
        let rowIndex = rowData.findIndex( row => row.budid==bid )
        if (rowIndex>-1) {
          assignRoles( rowData[rowIndex], res.budget_roles[bid] )
        }
      })

      Object.keys(res.sub_budget_roles || {}).forEach( sbid => {
        let rowIndex = rowData.findIndex( row => row.sbudid==sbid )
        if (rowIndex>-1) {
          assignRoles( rowData[rowIndex], res.sub_budget_roles[sbid] )
        }
      })

      // rowData is mutated in place, not returned
      return Object.assign( {}, state, {
        response: res,
        occupationId: action.params.occupation_id,
      })

    case OAR_SET_ROLE:
      var typeIdx = state.targetTypes.findIndex( t => t===action.role.target_type )
      var roleIdx = state.rowData.findIndex( row => row.resourceColumn[typeIdx]===action.role.target_id && row.resourceColumn.length===(typeIdx+1) )

      var rowNodeId = state.rowData[roleIdx].resourceColumn.join('-')
      var rowNode = state.gridApi.getRowNode( rowNodeId )

      var changedRoles = []
      state.permissionTypes.forEach( p => {
        if (state.rowData[roleIdx][p]!==action.role[p]) {
          state.rowData[roleIdx][p]=action.role[p]
          changedRoles.push(p)
        }
      })
      // Flash changed cells manually, ag-grid's flashes full table
      state.gridApi.flashCells( { rowNodes: [rowNode], columns: changedRoles });

      // rowData is mutated in place, not returned
      return state

    case OAR_REDRAW_INHERITANCE:
      const inheritRoles = (inheritedRoles, roles) => {
        return { 
          inherited_r: inheritedRoles.inherited_r || (roles || {}).r || (roles || {}).u || (roles || {}).c || (roles || {}).d,
          inherited_u: inheritedRoles.inherited_u || (roles || {}).u, 
          inherited_c: inheritedRoles.inherited_c || (roles || {}).c,
          inherited_d: inheritedRoles.inherited_d || (roles || {}).d
        }
      }
      const currentRoles = (row) => {
        return {r: row.r, u: row.u, c: row.c, d: row.d}
      }
      
      var inheritedRolesDefault = { inherited_r: false, inherited_u: false, inherited_c: false, inherited_d: false }
      var inheritedRoles = [ inheritedRolesDefault, inheritedRolesDefault, inheritedRolesDefault ]
      var lastLevel = 0
      var rowData = state.rowData

      rowData.forEach( row => {
        let level = row.resourceColumn.length - 1
        if (level<3) { // Organization, document of budget
          // get rid of previous parents if next budget
          if (level<=lastLevel){
            inheritedRoles.filter( (ir,idx) => idx>=level ).forEach( ir => ir=inheritedRolesDefault )
          }
          // init child data
          row.child_r = false
          row.child_u = false
          row.child_c = false
          row.child_d = false
        } else if (row.r || row.u || row.c || row.d) { // Sub-budget
          // Find budget to mark it as having sub-budgets with rights
          let pathString = JSON.stringify(row.resourceColumn.slice(0,3))
          let parentRow = state.rowData.find( row => JSON.stringify(row.resourceColumn)===pathString)
          if (parentRow) {
            parentRow.child_r = parentRow.child_r || row.r
            parentRow.child_u = parentRow.child_u || row.u
            parentRow.child_c = parentRow.child_c || row.c
            parentRow.child_d = parentRow.child_d || row.d
          }
        }
        
        // Inherit from previous level
        if (level>0)
          inheritedRoles[level] = inheritRoles( inheritedRoles[level-1], currentRoles(row) )
        // Inherit from itself as r can be inherited from other permissions
        inheritedRoles[level] = inheritRoles( inheritedRoles[level], currentRoles(row) )
        
        row.inherited_r = inheritedRoles[level].inherited_r
        row.inherited_u = inheritedRoles[level].inherited_u
        row.inherited_c = inheritedRoles[level].inherited_c
        row.inherited_d = inheritedRoles[level].inherited_d
        lastLevel = level
      })
      
      if (state.gridApi) {
        state.gridApi.refreshCells( {force: true} );
      }
      
      // rowData is mutated in place, not returned
      return Object.assign( {}, state, {
      })

    case OAR_EXPOSE_GRID_APIS:
      return Object.assign( {}, state, {
        gridApi: action.gridApi,
        gridColumnApi: action.gridColumnApi,
        gridOptions: action.gridOptions
      })

    case OAR_POST_REQUEST:
      return Object.assign( {}, state, {
        isPosting: true,
      })

    case OAR_POST_SUCCESS:
      return Object.assign( {}, state, {
        isPosting: false,
        postingSuccess: true,
      })
      
    case OAR_POST_FAILURE:
      console.log("Posting failure")
      console.error(action.error)
      return Object.assign( {}, state, {
        isPosting: false,
        postingSuccess: false,
        postingError: action.error,
      })

    case OAR_CLEAR_ERRORS:
      // refresh to notify renderers - remove spinners etc.
      state.gridApi.refreshCells( {force: true} )
      return Object.assign( {}, state, {
        isLoading: undefined,
        success: undefined,
        error: undefined,
        isPosting: undefined,
        postingSuccess: undefined,
        postingError: undefined,
      })

    case OAR_SET_LOCALE:
      moment.locale( action.locale )
      setLanguage( action.locale )
      return Object.assign( {}, state, {
        locale: action.locale
      })

    case OAR_SET_FILTER:
      if (state.gridApi) {
        if (!action.permission) {
          // Set filter after state has been set
          setTimeout( () => state.gridApi.setFilterModel(null), 0)
        } else {
          let filterModel = {}
          filterModel[action.permission] = { type: 'set', values: ['true'] }
          // Set filter after state has been set
          setTimeout( () => state.gridApi.setFilterModel(filterModel), 0)
        }
      }
      return Object.assign( {}, state, {
        filteredPermission: action.permission,
      })

    case OAR_TOGGLE_LEVEL_LOCK:
      return Object.assign( {}, state, {
        levelLock: !state.levelLock
      })

    default:
      return state;
  }
}
