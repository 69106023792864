import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Table from '../components/Table';

import * as MainActions from '../actions/MainActions';

const TableContainer = ({ actions, oRights, railsContext }) => (
  <Table {...{ actions, oRights, railsContext }} />
);
TableContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  oRights: PropTypes.object.isRequired,
  railsContext: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    oRights: state.oRights,
    railsContext: state.railsContext,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(MainActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableContainer);
