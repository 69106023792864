import { combineReducers, applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

//import reportTableReducers from '../../ReportTable/reducers';
import occupationAccessRightsReducers from '../../OccupationAccessRights/reducers';
import erpRecordsReducers from '../../ErpRecords/reducers';

/*
 *  Export a function that takes the props and returns a Redux store
 *  This is used so that 2 components can have the same store.
 */
export default (props, railsContext) => {
  // eslint-disable-next-line no-param-reassign
//  delete props.prerender;
  const combinedReducer = combineReducers( Object.assign({}, occupationAccessRightsReducers, erpRecordsReducers) );
  const newProps = { ...props, railsContext };

  return applyMiddleware(thunkMiddleware)(createStore)(combinedReducer, newProps);
};
